import { TABLE_EVENTS_TEXTBLOCKS } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
import { getGeneric } from '../generic'
import queryClient from '../../lib/reactQuery'
import supabase from '../../lib/supabase'

import type { EventTextBlock } from '../../lib/supabase'
import type { QueryConfig } from '../../lib/reactQuery'

export const getQueryKey = ({ textblockId }: GetEventTextBlockArgs) => [
	'textblock',
	textblockId,
]

type GetEventTextBlockArgs = {
	textblockId: string
}
const getEventTextBlockFunction = async ({
	textblockId,
}: GetEventTextBlockArgs) => {
	// getGeneric<EventTextBlock>({
	// 	tableName: TABLE_EVENTS_TEXTBLOCKS,
	// 	id: textblockId,
	// })
	const { data, error } = await supabase
		.from(TABLE_EVENTS_TEXTBLOCKS)
		.select('*')
		.order('created_at', { ascending: false })
		.limit(1)
		.single()

	return data
}

export const getEventTextBlock = async ({
	textblockId,
}: GetEventTextBlockArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey({ textblockId }),
		queryFn: () => getEventTextBlockFunction({ textblockId }),
	})

type UseGetEventTextBlockOptions = {
	textblockId: string
	options?: QueryConfig<typeof getEventTextBlockFunction>
}
export const useGetEventTextBlock = ({
	textblockId,
	options,
}: UseGetEventTextBlockOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey({ textblockId }),
		queryFn: () => getEventTextBlockFunction({ textblockId }),
	})
}

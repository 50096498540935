import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_CHAT_CONVERSATIONS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { Conversation } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'
import { getQueryKey } from './get'

type UpdateConversationArgs = {
	conversationId: string
	conversation: Partial<Omit<Conversation, 'id'>>
}
export const updateConversation = async ({
	conversationId,
	conversation,
}: UpdateConversationArgs) => {
	const { data, error } = await supabase
		.from(TABLE_CHAT_CONVERSATIONS)
		.update(conversation)
		.eq('id', conversationId)
		.select()
		.single()

	if (error) throw error

	return data
}

type UseUpdateConversationOptions = {
	options?: MutationConfig<typeof updateConversation>
}
export const useUpdateConversation = ({
	options,
}: UseUpdateConversationOptions = {}) => {
	return useMutation({
		onMutate: async (variables) => {
			const queryKey = getQueryKey({
				conversationId: variables.conversationId,
			})
			const previousConversation = queryClient.getQueryData(
				queryKey
			) as Conversation
			queryClient.setQueryData(queryKey, variables.conversation)
			return { previousConversation }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError(error, variables, context: any) {
			if (context) {
				queryClient.setQueryData(
					getQueryKey({ conversationId: variables.conversationId }),
					context.previousActivity
				)
			}
		},
		onSuccess: (data) => {
			queryClient.setQueryData(
				getQueryKey({ conversationId: data.id }),
				data
			)
		},
		...options,
		mutationFn: updateConversation,
	})
}

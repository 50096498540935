import queryClient from '../../lib/reactQuery'
import { TABLE_CHAT_CONVERSATIONS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'
import type { Conversation } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateConversationeArgs = {
	conversation: Conversation
}
export const createConversation = async ({
	conversation,
}: CreateConversationeArgs) =>
	createGeneric({ tableName: TABLE_CHAT_CONVERSATIONS, data: conversation })

type UseCreateConversationOptions = {
	options?: MutationConfig<typeof createConversation>
}
export const useCreateConversation = ({
	options,
}: UseCreateConversationOptions = {}) => {
	return useMutation({
		onSuccess: (conversation) => {
			if ('id' in conversation) {
				queryClient.setQueryData(
					['conversation', conversation.id],
					conversation
				)
			}
		},
		...options,
		mutationFn: createConversation,
	})
}

import React, { useState, useEffect, useRef } from 'react'
import type { Profile } from 'lib/supabase'
import {
	Button,
	Box,
	AppBar,
	Avatar,
	Paper,
	styled,
	Typography,
} from '@mui/material'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
} from '@mui/material'
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor'
import { getCurrentImgDataFunction } from 'react-filerobot-image-editor'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import { stringAvatar } from 'utils'
import AppHeader from 'components/AppHeader'

type Props = {
	readonly profile: Profile
	onSave: () => void
	onCancel: () => void
	onSetWaiting: (val: boolean) => void
}

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

const EditAvatar: React.FC<Props> = ({
	profile,
	onSave,
	onCancel,
	onSetWaiting,
}) => {
	const {
		getAvatarUrl,
		uploadScratchFile,
		deleteScratchFiles,
		getPublicUrl,
		uploadAvatar,
		publishAvatar,
		deleteAvatar,
	} = useUserContext()
	const [avatarUrl, setAvatarUrl] = useState<string>('')
	const [origAvatarUrl, setOrigAvatarUrl] = useState<string>('')
	const [scratchUrl, setScratchUrl] = useState<string>('')
	const [initials, setInitials] = useState<string>('')
	const [showAvatarEditDialog, setShowAvatarEditDialog] = useState(false)
	const [filesToDelete, setFilesToDelete] = useState<string[]>([])
	const { setGroupMemberAvatar } = useGroupsContext()

	const editorRef = useRef<getCurrentImgDataFunction | null>(null)

	useEffect(() => {
		const fetchAvatarUrl = async () => {
			if (profile && scratchUrl === '') {
				const url = await getAvatarUrl(profile.id + '.jpeg')
				setAvatarUrl(url)
				setOrigAvatarUrl(url)
			}
		}

		const fetchUserInitials = () => {
			const names = profile?.full_name.split(' ')
			const firstInitial = names[0].slice(0, 1)
			const lastInitial = names[names.length - 1].slice(0, 1)
			setInitials(firstInitial + lastInitial)
		}

		fetchAvatarUrl()
		fetchUserInitials()
		console.log('UseEffect triggered based on profile')
	}, [profile])

	useEffect(() => {
		let str = '========================================='
		str += '\nscratchUrl: ' + scratchUrl
		str += '\navatarUrl: ' + avatarUrl
		str += '\norigAvatarUrl: ' + origAvatarUrl
		console.log(str)
	}, [avatarUrl])

	const handleClearAvatar = async () => {
		setAvatarUrl('')
		setScratchUrl('')
	}

	const handleResetAvatar = async () => {
		setScratchUrl('')
		setAvatarUrl(origAvatarUrl)
	}

	const handleScratchUpload = async (files: FileList | null) => {
		if (files?.[0]) {
			uploadScratchFile(files[0]).then(async (resp) => {
				if (resp) {
					const publicUrl = await getPublicUrl(resp)
					setScratchUrl(publicUrl)
					setShowAvatarEditDialog(true)
					setFilesToDelete((prev) => [...prev, resp])
				}
			})
		} else {
			console.error('EditAvatar::handleUpload: No file available')
		}
	}

	const wipeScratch = async () => {
		console.log('Scratch files to delete:', filesToDelete)
		if (scratchUrl) {
			const uniqueFilesToDelete = Array.from(new Set(filesToDelete))
			await deleteScratchFiles(uniqueFilesToDelete)
		}
	}

	const onNewAvatar = async (draft: boolean) => {
		const fileName = scratchUrl.split('/').pop() || ''
		const fileType = fileName.split('.').pop() || ''
		if (editorRef.current) {
			const editedImage = editorRef.current({
				name: fileName,
				extension: fileType,
			}).imageData
			if (editedImage.imageBase64) {
				handleUploadAvatar({
					imageBase64: editedImage.imageBase64,
					draft: draft,
				})
			} else {
				console.error('No edited image available')
			}
		}
	}

	const handleUploadAvatar = (editedImageObject: {
		imageBase64: string
		draft: boolean
	}) => {
		const newFile = base64ToFile(
			editedImageObject.imageBase64,
			profile.id + '.jpeg'
		)
		uploadAvatar(newFile, editedImageObject.draft).then((newAvatarPath) => {
			if (editedImageObject.draft) {
				getPublicUrl(newAvatarPath).then((publicUrl) => {
					setAvatarUrl(publicUrl + '?' + new Date().getTime())
					const file = profile.id + '.jpeg'
					if (!filesToDelete.includes(file))
						setFilesToDelete((prev) => [
							...prev,
							profile.id + '.jpeg',
						])
				})
			} else {
				getAvatarUrl(newAvatarPath).then((secureUrl) => {
					setAvatarUrl(secureUrl)
				})
			}
			setShowAvatarEditDialog(false)
		})
	}

	const base64ToFile = (base64String: string, fileName: string): File => {
		const byteString = atob(base64String.split(',')[1])
		const mimeString = base64String
			.split(',')[0]
			.split(':')[1]
			.split(';')[0]

		const ab = new ArrayBuffer(byteString.length)
		const ia = new Uint8Array(ab)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}

		const blob = new Blob([ab], { type: mimeString })
		return new File([blob], fileName, { type: mimeString })
	}

	const handleDialogClose = () => {
		setShowAvatarEditDialog(false)
	}

	const handleAbandonChanges = () => {
		wipeScratch()
		onCancel()
	}

	const handleSaveChanges = () => {
		if (scratchUrl === '' && avatarUrl === '') {
			// user has reset their avatar to no avatar
			console.log('user has cleared their avatar')
			onSetWaiting(true)
			onSave()
			if (origAvatarUrl !== '') {
				const fileName = profile.id + '.jpeg'
				deleteAvatar(fileName)
				setGroupMemberAvatar(profile.id).then(() => {
					wipeScratch()
					onSetWaiting(false)
				})
			}
		} else {
			onSetWaiting(true)
			onSave()
			const removeTimestamp = avatarUrl.split('?')[0]
			const fileName = removeTimestamp.split('/').pop() || ''
			publishAvatar(fileName).then(() => {
				setGroupMemberAvatar(profile.id).then(() => {
					wipeScratch()
					onSetWaiting(false)
				})
			})
		}
	}

	return (
		<>
			<Box flexGrow={1}>
				<AppHeader
					// leftComponent={
					// 	<Button
					// 		sx={{ color: '#0195f5' }}
					// 		onClick={handleAbandonChanges}
					// 	>
					// 		Cancel
					// 	</Button>
					// }
					title={'Update Avatar'}
				/>

				{/* PREVIEW AVATAR w/ ACTIONS */}
				<Paper
					elevation={3}
					style={{ margin: '10px', padding: '20px 20px' }}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography
							fontWeight={'500'}
							textTransform={'uppercase'}
						>
							Current Avatar
						</Typography>
						<Avatar
							src={avatarUrl}
							style={{ width: '96px', height: '96px' }}
							{...stringAvatar(profile?.full_name)}
							slotProps={{
								img: {
									loading: 'lazy',
								},
							}}
						/>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Button
								variant='text'
								fullWidth
								disabled={!avatarUrl}
								style={{ maxWidth: '250px' }}
								onClick={handleClearAvatar}
							>
								Clear
							</Button>
							<Button
								variant='text'
								fullWidth
								disabled={!origAvatarUrl}
								style={{ maxWidth: '250px' }}
								onClick={handleResetAvatar}
							>
								Reset
							</Button>
							<Button
								component='label'
								role={undefined}
								variant='text'
								fullWidth
								style={{ maxWidth: '250px' }}
							>
								New
								<VisuallyHiddenInput
									type='file'
									accept='image/jpeg, image/png, image/jpg'
									onChange={(event) =>
										handleScratchUpload(event.target.files)
									}
								/>
							</Button>
						</div>
					</div>
				</Paper>

				{/* EDIT NEW AVATAR IMAGE */}
				<TableContainer sx={{ margin: '20px 20px' }}>
					<Table sx={{ maxWidth: '80%' }}>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2}>
									<Typography fontWeight={'500'}>
										Instructions to Change Your Avatar
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ verticalAlign: 'middle' }}>
								<TableCell>
									<Typography fontWeight={'500'}>
										CLEAR
									</Typography>
								</TableCell>
								<TableCell>Clears image avatar</TableCell>
							</TableRow>
							<TableRow sx={{ verticalAlign: 'middle' }}>
								<TableCell>
									<Typography fontWeight={'500'}>
										RESET
									</Typography>
								</TableCell>
								<TableCell>
									Restores your original avatar
								</TableCell>
							</TableRow>
							<TableRow sx={{ verticalAlign: 'middle' }}>
								<TableCell>
									<Typography fontWeight={'500'}>
										NEW
									</Typography>
								</TableCell>
								<TableCell>Upload a new avatar image</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<AppBar
					position='fixed'
					sx={{
						top: 'auto',
						bottom: 0,
						background: '#ffffff',
						paddingBottom: '60px',
					}}
				>
					<div
						style={{
							padding: '20px 20px 0px 0px',
							marginRight: '20px',
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<Button
							variant='outlined'
							style={{ maxWidth: '250px' }}
							onClick={handleAbandonChanges}
						>
							Cancel
						</Button>

						<Button
							variant='contained'
							disabled={origAvatarUrl === avatarUrl}
							style={{ maxWidth: '250px' }}
							onClick={handleSaveChanges}
						>
							Save Changes
						</Button>
					</div>
				</AppBar>
			</Box>

			<Dialog
				open={showAvatarEditDialog}
				onClose={handleDialogClose}
				fullWidth
			>
				<DialogTitle>Adjust Avatar</DialogTitle>
				<DialogContent>
					{scratchUrl !== '' && (
						<FilerobotImageEditor
							source={scratchUrl}
							onBeforeSave={() => false}
							getCurrentImgDataFnRef={editorRef!}
							showCanvasOnly={true}
							defaultSavedImageType='jpeg'
							defaultSavedImageQuality={0.8}
							savingPixelRatio={100}
							previewPixelRatio={100}
							avoidChangesNotSavedAlertOnLeave={true}
							removeSaveButton
							observePluginContainerSize
							Crop={{ ratio: 'ellipse' }}
							tabsIds={[TABS.RESIZE]}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={handleDialogClose}>
						Cancel
					</Button>
					<Button
						variant='contained'
						onClick={() => onNewAvatar(true)}
					>
						Apply
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default EditAvatar

import queryClient from 'lib/reactQuery'
import supabase from 'lib/supabase'
import { TABLE_POLLS_CHOICES } from 'lib/supabase'

import type { QueryConfig } from 'lib/reactQuery'
import { useQuery } from '@tanstack/react-query'

type GetChoicesByPollIdArgs = {
	pollId?: string
}
const getChoicesByPollIdFunction = async ({
	pollId,
}: GetChoicesByPollIdArgs) => {
	if (!pollId) return []

	const { data, error } = await supabase
		.from(TABLE_POLLS_CHOICES)
		.select('*')
		.eq('poll_id', pollId)
		.order('sort_order', { ascending: true })

	if (error) throw error

	return data
}

export const getChoicesByPollId = async ({ pollId }: GetChoicesByPollIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['choices', 'byPollId', pollId],
		queryFn: () => getChoicesByPollIdFunction({ pollId }),
	})

type UseGetChoicesByPollIdOptions = {
	pollId?: string
	options?: QueryConfig<typeof getChoicesByPollIdFunction>
}
export const useGetChoicesByPollId = ({
	pollId,
	options,
}: UseGetChoicesByPollIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['choices', 'byPollId', pollId],
		queryFn: () => getChoicesByPollIdFunction({ pollId }),
	})
}

import { useMutation } from '@tanstack/react-query'
import { getManyQueryKey } from 'api/group/getMany'
import { getManyQueryKey as getManyProfilesQueryKey } from 'api/profile/getManyByGroupId'
import queryClient, { MutationConfig } from 'lib/reactQuery'
import supabase, { TABLE_GROUPS_MEMBERSHIP } from 'lib/supabase'

type RemoveUserFromGroupArgs = {
	userId: string
	groupId: string
}
const removeUserFromGroupFunc = async ({
	userId,
	groupId,
}: RemoveUserFromGroupArgs) => {
	const { error } = await supabase
		.from(TABLE_GROUPS_MEMBERSHIP)
		.delete()
		.eq('user_id', userId)
		.eq('group_id', groupId)

	if (error) throw error
}

export const removeUserFromGroup = async (args: RemoveUserFromGroupArgs) => {
	await removeUserFromGroupFunc(args)
	queryClient.invalidateQueries({ queryKey: getManyQueryKey(args.userId) })
	queryClient.invalidateQueries({
		queryKey: getManyProfilesQueryKey(args.groupId),
	})
}

type UseRemoveUserFromGroupArgs = {
	options?: MutationConfig<typeof removeUserFromGroupFunc>
}
export const useRemoveUserFromGroup = ({
	options,
}: UseRemoveUserFromGroupArgs = {}) => {
	return useMutation({
		onSuccess(_, variables) {
			queryClient.invalidateQueries({
				queryKey: getManyQueryKey(variables.userId),
			})
			queryClient.invalidateQueries({
				queryKey: getManyProfilesQueryKey(variables.groupId),
			})
		},
		...options,
		mutationFn: removeUserFromGroupFunc,
	})
}

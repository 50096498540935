import { useQuery } from '@tanstack/react-query'
import queryClient, { type QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_CHAT_REACTIONS } from 'lib/supabase'

export const getReactionsQueryKey = ({
	messageId,
	chatId,
}: GetReactionsByMessageIdArgs) => [
	'message',
	messageId,
	'chat',
	chatId,
	'reactions',
]

type GetReactionsByMessageIdArgs = {
	messageId: string
	chatId: string
}
const getReactionsByMessageIdFunction = async ({
	messageId,
	chatId,
}: GetReactionsByMessageIdArgs) => {
	const { data, error } = await supabase
		.from(TABLE_CHAT_REACTIONS)
		.select('*')
		.eq('message_id', messageId)

	if (error) throw error

	return data
}

export const getReactionsByMessageId = async ({
	messageId,
	chatId,
}: GetReactionsByMessageIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['reactionsById', messageId],
		queryFn: () => getReactionsByMessageIdFunction({ messageId, chatId }),
	})

type UseGetReactionsByMessageIdOptions = {
	messageId: string
	chatId: string
	options?: QueryConfig<typeof getReactionsByMessageIdFunction>
}
export const useGetReactionsByMessageId = ({
	messageId,
	chatId,
	options,
}: UseGetReactionsByMessageIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['reactionsById', messageId, chatId],
		queryFn: () => getReactionsByMessageIdFunction({ messageId, chatId }),
	})
}

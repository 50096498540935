import {
	useGetReactionsByMessageId as useGetReactions,
	getReactionsQueryKey as getReactionsQueryKey,
} from 'api/messages'
import queryClient from 'lib/reactQuery'
import supabase, { TABLE_CHAT_REACTIONS, type Reaction } from 'lib/supabase'
import { useEffect } from 'react'
// import { getQueryKey } from 'api/conversations'
// import { useUserContext } from 'contexts/UserContext'

type Args = {
	messageId: string
	chatId: string
}

const useReactions = ({ messageId, chatId }: Args) => {
	const reactions = useGetReactions({
		messageId,
		chatId,
	})

	useEffect(() => {
		const sub = supabase
			.channel('reactions-db-changes')
			.on(
				'postgres_changes',
				{
					event: '*',
					schema: 'public',
					table: TABLE_CHAT_REACTIONS,
				},
				async (payload) => {
					console.log('useReactions::Payload::', payload.eventType)

					// if (payload.eventType === 'DELETE') {
					// 	queryClient.removeQueries({
					// 		queryKey: getQueryKey({
					// 			conversationId: (payload.old as Conversation).id,
					// 		}),
					// 	})
					// 	await queryClient.invalidateQueries({
					// 		queryKey: getConversationsQueryKey({
					// 			userId,
					// 			groupId,
					// 		}),
					// 	})
					// }

					// if (
					// 	payload.eventType === 'UPDATE' &&
					// 	conversations.data?.findIndex(
					// 		(a) => a.id === (payload.new as Conversation).id
					// 	) !== -1
					// ) {
					// 	await queryClient.setQueryData(
					// 		getQueryKey({
					// 			conversationId: (payload.new as Conversation).id,
					// 		}),
					// 		payload.new
					// 	)
					// 	await queryClient.invalidateQueries({
					// 		queryKey: getConversationsQueryKey({
					// 			userId,
					// 			groupId,
					// 		}),
					// 	})
					// }

					if (
						payload.eventType === 'INSERT' &&
						(payload.new as Reaction).message_id === messageId
					) {
						console.log('new reaction added to current message')
						// 	payload.eventType === 'INSERT' &&
						// 	(payload.new as Reaction).group_id ===
						// 		activeGroup?.id
						// ) {
						await queryClient.setQueryData(
							getReactionsQueryKey({
								messageId: (payload.new as Reaction).message_id,
								chatId: '',
							}),
							payload.new
						)
						await queryClient.refetchQueries({
							queryKey: getReactionsQueryKey({
								messageId,
								chatId,
							}),
						})
					}
				}
			)
			.subscribe((status, err) => {
				if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
					if (err) console.error(err)
					sub.unsubscribe()
				}
			})

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, reactions.data])

	return reactions
}

export default useReactions

import React, { useState } from 'react'
import type { Activity, GroupVenue } from 'lib/supabase'
import { Box, Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'
import { useUserContext } from 'contexts/UserContext'
import ActivityForm, {
	ActivityFormValues,
} from 'components/forms/prebuilt/ActivityForm'
import { DEFAULT_ERROR_MESSAGE, combineDateAndTime } from 'utils'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'
import { set } from 'react-hook-form'

type Props = {
	venues: GroupVenue[]
	activity: Activity
	onSave: (newActivity: Partial<Activity>, notifyMembers: boolean) => void
	onCancel: (dialogAction: string, actionTaken: boolean) => void
	onDeleteActivity: () => void
	onCreateVenue: (venue: GroupVenue) => Promise<Error | void>
	onSendNotifications: (
		selected: string[],
		message: string,
		type: string
	) => void
}

const EditActivity: React.FC<Props> = ({
	venues,
	activity,
	onSave,
	onCancel,
	onDeleteActivity,
	onCreateVenue,
	onSendNotifications,
}) => {
	type DialogProps = {
		dialogAction: string
		dialogType: string
		objectType: string
		title: string
		textFieldLabel: string
		selectAll: boolean
		forceSendAll: boolean
		showConfirm: boolean
		showConfirmText: string
		confirmOnly: boolean
	}

	const deleteActivityDialogProps: DialogProps = {
		dialogAction: 'delete',
		dialogType: 'confirm',
		objectType: 'activity',
		title: 'Delete Activity',
		textFieldLabel: 'Additional Information (optional)',
		selectAll: true,
		forceSendAll: false,
		showConfirm: true,
		showConfirmText: 'Confirm Delete Activity',
		confirmOnly: false,
	}

	const editActivityDialogProps: DialogProps = {
		dialogAction: 'update',
		dialogType: 'confirm',
		objectType: 'activity',
		title: 'Update Activity',
		textFieldLabel: 'Additional Information (optional)',
		selectAll: true,
		forceSendAll: false,
		showConfirm: true,
		showConfirmText: 'Confirm Save Changes',
		confirmOnly: false,
	}

	const { showSnackbar } = useSnackbarContext()
	const { profile, activeGroup } = useUserContext()
	const navigate = useNavigate()

	const [showNotificationDialog, setShowNotificationDialog] = useState(false)
	const [updatedActivity, setUpdatedActivity] =
		useState<Partial<Activity> | null>(null)
	const [dialogProps, setDialogProps] = useState<DialogProps>({
		dialogAction: '',
		dialogType: '',
		objectType: '',
		title: '',
		textFieldLabel: '',
		selectAll: false,
		forceSendAll: false,
		showConfirm: false,
		showConfirmText: '',
		confirmOnly: false,
	})

	const openConfirmDelete = () => {
		setDialogProps(deleteActivityDialogProps)
		setShowNotificationDialog(true)
	}

	const handleQuickAddVenue = async (venue: GroupVenue) => {
		const error = await onCreateVenue(venue)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return null
		}

		return venue
	}

	const submitForm = (data: ActivityFormValues) => {
		const dateTime = combineDateAndTime(data.date, data.time, data.timezone)

		const newActivity: Partial<Activity> = {
			name: data.name,
			venue_id: data.venue,
			datetime: dateTime,
			note: data.note || null,
			link: data.url || null,
			max_spots: data.maxSpots,
		}

		console.log('submitForm called')
		setUpdatedActivity(newActivity)
		setDialogProps(editActivityDialogProps)
		setShowNotificationDialog(true)
	}

	const handleUpdateDeleteAction = async () => {
		console.log(
			'EditActivity::handleUpdateDeleteAction: ',
			dialogProps.dialogType,
			dialogProps.dialogAction
		)
		// HANDLE DELETE ACTIVITY
		if (dialogProps.dialogAction === 'delete') {
			onDeleteActivity()
		}

		// HANDLE UPDATE ACTIVITY
		if (dialogProps.dialogAction === 'update' && updatedActivity) {
			onSave(updatedActivity, true)
			setUpdatedActivity(null)
		}
	}

	const handleCancel = (actionTaken: boolean) => {
		if (showNotificationDialog && actionTaken) {
			onCancel(dialogProps.dialogAction, actionTaken)
			setShowNotificationDialog(false)
		} else {
			if (showNotificationDialog) {
				// action not taken on confirm dialog
				setShowNotificationDialog(false)
			} else {
				// user clicked cancel on header
				onCancel('cancel-header', false)
			}
		}
	}

	const handleSendNotifications = async (
		sendList: string[],
		addlMessage: string
	) => {
		// SEND NOTIFICATIONS IF NOTIFIFYING
		onSendNotifications(sendList, addlMessage, dialogProps.dialogAction)

		// CLOSE DIALOG
		setShowNotificationDialog(false)
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppHeader
				leftComponent={
					<Button onClick={() => handleCancel(false)}>Cancel</Button>
				}
				title='Edit Activity'
			/>

			<ActivityForm
				onSubmit={submitForm}
				venues={venues}
				defaultValues={{
					name: activity?.name || '',
					venue: activity?.venue_id || '',
					date: activity?.datetime || DateTime.now().toISODate(),
					time: activity?.datetime || DateTime.now().toISODate(),
					timezone:
						activeGroup?.default_timezone ||
						DateTime.local().zoneName,
					note: activity?.note || '',
					url: activity?.link || '',
					maxSpots: activity?.max_spots || 0,
				}}
				onQuickAddVenue={handleQuickAddVenue}
			/>

			<SendNotificationDialog
				{...dialogProps}
				isOpen={showNotificationDialog}
				onCancel={(actionTaken: boolean) => handleCancel(actionTaken)}
				onUpdateDeleteAction={handleUpdateDeleteAction}
				onSendNotifications={(selected: string[], message: string) => {
					handleSendNotifications(selected, message)
				}}
				activeGroup={profile?.active_group || undefined}
				profileId={profile?.id || undefined}
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						textAlign: 'center',
						margin: '10px 40px',
					}}
				>
					<Button
						variant='contained'
						fullWidth
						type='submit'
						form='edit-form'
						style={{ marginBottom: '10px' }}
					>
						Save Changes
					</Button>
					<Button
						variant='outlined'
						color='error'
						fullWidth
						onClick={openConfirmDelete}
					>
						Delete Activity
					</Button>
				</div>
			</AppBar>
		</Box>
	)
}

export default EditActivity

import React, { useState } from 'react'
import {
	IconButton,
	TextField,
	Button,
	Typography,
	LinearProgress,
	Stack,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { sendServerRequest } from 'vercel/apicalls'
import { useCreatePrompt } from 'api/prompts/create'
import { v4 as UUIDV4 } from 'uuid'
import { DateTime } from 'luxon'

const AIX = () => {
	const navigate = useNavigate()
	const [query, setQuery] = useState('')
	const [waiting, setWaiting] = useState(false)
	const [response, setResponse] = useState('')
	const createPrompt = useCreatePrompt()

	const setMessageToSend = (message: string) => {
		setQuery(message)
	}

	const resetPage = () => {
		setQuery('')
		setResponse('')
		setWaiting(false)
	}

	const extractJSON = (text: string) => {
		// find the string MATERIALS JSON in the source string
		// remove all text from MATERIALS_JSON on and set as JSON_STRING variable
		// return remaining string minus JSON object as TEXT_STRING variable
		const jsonStartIndex = text.indexOf('MATERIALS_JSON')
		if (jsonStartIndex !== -1) {
			const jsonString = text.substring(jsonStartIndex)
			const remainingText = text.substring(0, jsonStartIndex).trim()
			return { jsonString, remainingText }
		}
		return { jsonString: '', remainingText: text }
	}

	const sendQuery = () => {
		// Send query to AI model
		const dataPrompt =
			'In the same response, seperately provide a JSON object for the list of materials labeled MATERIALS_JSON and the list of volunteers labeled VOLUNTEERS_JSON for the event.  Ensure there is no further text after both JSON reposonses.'
		const host = import.meta.env.VITE_VERCELAPI_HOST
		const furl = host + 'anthropic/query'
		let parms = '?qs=' + encodeURIComponent(query || '')
		parms += '&dp=' + encodeURIComponent(dataPrompt)

		setWaiting(true)
		sendServerRequest('GET', furl + parms)
			.then((data) => {
				setResponse(data.response.content[0].text)
				console.log(data.response.content[0].text)
				setWaiting(false)
			})
			.catch((error) => {
				console.error(error)
			})
		createPrompt.mutateAsync({
			prompt: {
				created_at: DateTime.now().toISO(),
				id: UUIDV4(),
				prompt: query,
			},
		})
	}

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate('/prototypes')}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'AI Prototype'}
			/>
			<div style={{ margin: '20px' }}>
				<TextField
					id='outlined'
					label='Describe the type of event you are planning...'
					variant='outlined'
					multiline
					rows={4}
					fullWidth
					margin='normal'
					onChange={(e) => setMessageToSend(e.target.value)}
				/>
				<Stack spacing={2} direction='row'>
					<Button
						variant='contained'
						disabled={waiting}
						onClick={sendQuery}
					>
						Submit
					</Button>
					<Button variant='text' onClick={resetPage}>
						Reset
					</Button>
				</Stack>
			</div>
			<div style={{ margin: '20px' }}>
				{waiting && <LinearProgress />}
				<Typography
					style={{ whiteSpace: 'pre-line', paddingBottom: '60px' }}
				>
					{response}
				</Typography>
			</div>
		</>
	)
}

export default AIX

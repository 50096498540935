import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Typography } from '@mui/material'

type SortableItemProps = {
	id: string
}

export function SortableItem(props: SortableItemProps) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: props.id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			<div
				style={{
					width: '100%',
					minHeight: '60px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					border: '1px solid #e0e0e0',
					borderRadius: '5px',
					touchAction: 'manipulation',
				}}
			>
				<Typography paddingLeft={'20px'}>{props.id}</Typography>
				<DragIndicatorIcon
					style={{ color: '#757575', paddingRight: '5px' }}
				/>
			</div>
		</div>
	)
}

import supabase from '../../lib/supabase'
import { Reaction } from '../../lib/supabase'

export const addReaction = async (reaction: Reaction) => {
	const { data, error } = await supabase
		.from('chat_reactions')
		.insert([reaction])

	if (error) {
		throw new Error(error.message)
	}
	return data
}

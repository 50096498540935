import queryClient, { QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_PROFILES, type Group } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'

export const getManyQueryKey = (userId?: string) => [
	'profile',
	userId,
	'groups',
]

// TODO make the userId required
type GetGroupsArgs = {
	userId?: string
}
const getGroupsFunc = async ({ userId }: GetGroupsArgs): Promise<Group[]> => {
	if (!userId) throw new Error('userId is undefined')

	const { data, error } = await supabase
		.from(TABLE_PROFILES)
		.select(
			`
			groups_membership (
				groups (*)
			)
		`
		)
		.eq('id', userId)
		.neq('groups_membership.groups.status', 'deleted')
		.single()

	if (error) throw error

	return data.groups_membership
		.map((ug) => ug.groups)
		.filter((group) => group != null) as Group[]
}

export const getGroups = async ({ userId }: GetGroupsArgs) =>
	queryClient.fetchQuery({
		queryKey: getManyQueryKey(userId),
		queryFn: () => getGroupsFunc({ userId }),
	})

type UseGetGroupsArgs = {
	userId?: string
	options?: QueryConfig<typeof getGroupsFunc>
}
export const useGetGroups = ({ userId, options }: UseGetGroupsArgs) => {
	return useQuery({
		...options,
		queryKey: getManyQueryKey(userId),
		queryFn: () => getGroupsFunc({ userId }),
	})
}

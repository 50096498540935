import queryClient from '../../lib/reactQuery'
import { createGeneric } from '../generic'
import { TABLE_POLLS_CHOICES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { PollChoice } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateChoiceArgs = {
	choice: PollChoice
}
export const createChoice = async ({ choice }: CreateChoiceArgs) =>
	createGeneric({ tableName: TABLE_POLLS_CHOICES, data: choice })

type UseCreateChoiceOptions = {
	options?: MutationConfig<typeof createChoice>
}
export const useCreateChoice = ({ options }: UseCreateChoiceOptions = {}) => {
	return useMutation({
		onSuccess: (choice) => {
			if ('id' in choice) {
				queryClient.setQueryData(['choice', choice.id], choice)
				queryClient.invalidateQueries({ queryKey: ['choice'] })
			}
		},
		...options,
		mutationFn: createChoice,
	})
}

import React, { useEffect, useState } from 'react'
import { Button, Typography, Stack } from '@mui/material'
import { ThumbUpOffAlt, ThumbDownOffAlt } from '@mui/icons-material'
import { DateTime } from 'luxon'
import type { JoinedActivity } from '../../lib/supabase'

type Props = {
	activity: JoinedActivity
	profileId: string
	// introSteps: boolean
	onAttendance: (
		profileId: string,
		activityId: string,
		status: 'in' | 'out' | '',
		action: string
	) => void
}

const ActivityAttendingBlock: React.FC<Props> = ({
	activity,
	profileId,
	onAttendance,
}) => {
	const [isIn, setIsIn] = useState(false)
	const [isOut, setIsOut] = useState(false)
	const [isExpired, setIsExpired] = useState(false)
	const [isFull, setIsFull] = useState(false)
	const [guestCount, setGuestCount] = useState(0)

	useEffect(() => {
		setGuestCount(activity.activities_guests.length)
		setIsIn(
			!!activity.activities_attendance.find(
				(attendance) =>
					attendance.profile_id === profileId &&
					attendance.attendance_status === 'in'
			)
		)
		setIsOut(
			!!activity.activities_attendance.find(
				(attendance) =>
					attendance.profile_id === profileId &&
					attendance.attendance_status === 'out'
			)
		)
		setIsExpired(DateTime.fromISO(activity.datetime) < DateTime.now())
		setIsFull(
			activity.max_spots > 0 &&
				activity.activities_attendance.filter(
					(attendance) => attendance.attendance_status === 'in'
				).length +
					activity.activities_guests.length ===
					activity.max_spots
		)
	}, [activity, profileId])

	const handleGoing = () => {
		let status: 'in' | 'out' | ''
		let action = ''
		if (isIn) {
			status = ''
			action = 'delete'
		} else if (isOut) {
			status = 'in'
			action = 'update'
		} else {
			status = 'in'
			action = 'create'
		}
		onAttendance(profileId, activity.id, status, action)
	}

	const handleNotGoing = () => {
		let status: 'in' | 'out' | ''
		let action = ''
		if (isIn) {
			status = 'out'
			action = 'update'
		} else if (isOut) {
			status = ''
			action = 'delete'
		} else {
			status = 'out'
			action = 'create'
		}
		onAttendance(profileId, activity.id, status, action)
	}

	return (
		<Stack direction='column'>
			<Stack direction='row' justifyContent={'center'} gap={4}>
				<Button
					variant={isIn ? 'contained' : 'outlined'}
					color={isIn ? 'success' : 'primary'}
					disabled={isExpired || isFull}
					onClick={handleGoing}
				>
					<ThumbUpOffAlt />
				</Button>
				<Button
					variant={isOut ? 'contained' : 'outlined'}
					color={isOut ? 'warning' : 'primary'}
					disabled={isExpired}
					onClick={handleNotGoing}
				>
					<ThumbDownOffAlt />
				</Button>
			</Stack>
			<div
				style={{
					animationName: 'blink',
					animationDuration: '2s',
					animationIterationCount: '1',
					animationTimingFunction: 'ease-in-out',
					borderRadius: '5px',
					textAlign: 'center',
					marginTop: '5px',
				}}
			>
				<Typography
					fontSize={'0.8rem'}
					fontStyle={'italic'}
					style={{ padding: '10px 0px' }}
				>
					{activity.max_spots > 0
						? `${
								activity.activities_attendance.filter(
									(attendance) =>
										attendance.attendance_status === 'in'
								).length + guestCount
						  } of ${activity.max_spots} attending`
						: `${
								activity.activities_attendance.filter(
									(attendance) =>
										attendance.attendance_status === 'in'
								).length + guestCount
						  } attending`}
				</Typography>
			</div>
		</Stack>
	)
}

export default ActivityAttendingBlock

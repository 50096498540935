import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useUserContext } from './UserContext'
import {
	SubscriptionInfo,
	StripeData,
	normalizeSubscriptionStatus,
	getSubscriptionInfo,
} from 'vercel/stripe-utils'
import { useGroupsContext } from 'contexts/GroupsContext'
import { getGroup } from 'api/group'
import { getProfile } from 'api/profile'
// import { useUpdateProfile } from 'api/profile'

type SubscriptionContextType = {
	hasSubscription: boolean
	subscriptionStatus: 'active' | 'inactive' | 'expired' | null
	trialSubscription: boolean
	subscriberFullName: string
}

const SubscriptionContext = React.createContext<SubscriptionContextType>({
	hasSubscription: true,
	subscriptionStatus: null,
	trialSubscription: false,
	subscriberFullName: '',
})

export const useSubscriptionContext = () =>
	React.useContext(SubscriptionContext)

export const SubscriptionContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { profile } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	// const updateProfile = useUpdateProfile()
	const [activeSubscription, setActiveSubscription] = useState(true)
	const [userIsAdmin, setUserIsAdmin] = useState(false)
	const [adminFullName, setAdminFullName] = useState('')
	const [subStatus, setSubStatus] = useState('')
	const [isTrialing, setIsTrialing] = useState(false)

	// MOVING ALL THIS TO SUBSCRIPTION_CONTEXT
	useEffect(() => {
		if (profile) {
			if (profile.active_group)
				setUserIsAdmin(userIsAdminOfGroup(profile.active_group))

			// check Stripe subscription status if profile is group admin and has a stripe_customer_id
			// how to make sure this not super chatty?
			if (userIsAdmin && profile.stripe_customer_id)
				checkStripeSubscription(profile.id, profile.stripe_customer_id)

			// SIGNED IN USER IS NOT GROUP ADMIN
			if (!userIsAdmin) {
				getGroup({
					groupId: profile?.active_group ?? '',
				}).then((gprops) => {
					getProfile({ profileId: gprops?.admin ?? '' }).then(
						(gadmin) => {
							setAdminFullName(gadmin.full_name)
							if (gadmin.stripe_customer_id)
								checkStripeSubscription(
									gadmin.id,
									gadmin.stripe_customer_id
								)
						}
					)
				})
			}
		}
	}, [profile])

	const checkStripeSubscription = async (uid: string, sid: string) => {
		const data: StripeData = await getSubscriptionInfo(sid)
		const sub = data.subscription as SubscriptionInfo

		// setActiveSubscription(sub.status === 'expired' ? false : true)
		// setSubStatus(sub.status)
		// setIsTrialing(sub.trialing)

		// IGNORING STRIPE SUBSCRIPTION STATUS FOR NOW
		setActiveSubscription(true)
		setSubStatus('active')
		setIsTrialing(false)
	}

	return (
		<SubscriptionContext.Provider
			value={
				{
					hasSubscription: activeSubscription,
					subscriptionStatus: subStatus,
					trialSubscription: isTrialing,
					subscriberFullName: adminFullName,
				} as SubscriptionContextType
			}
		>
			{children}
		</SubscriptionContext.Provider>
	)
}

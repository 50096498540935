import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
	Paper,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Typography,
	SvgIcon,
} from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import { useUserContext } from 'contexts/UserContext'
import { JoinedActivity } from 'lib/supabase'
import useActivties from '../../hooks/useActivities'
import { useCreateAttendance } from 'api/activity/createAttendance'
import { useDeleteAttendance } from 'api/activity/deleteAttendance'
import { useUpdateAttendance } from 'api/activity/updateAttendance'
import { useUpdateActivity } from 'api/activity'
import { getActivitiesQueryKey } from 'api/activity/getActivitiesByGroupId'
import { MainOutletContext } from '../Main/MainLayout'
import { DateTime } from 'luxon'
import queryClient from 'lib/reactQuery'
import introJs from 'intro.js'
import { track } from '@vercel/analytics'
import ActivityInfoBox from './ActivityInfoBox'
import ActivityDateBox from './ActivityDateBox'
import { WELCOME_STEP_COOKIE } from '../Welcome/messageFlowMachine'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

type GroupedActivities = {
	[key: string]: JoinedActivity[]
}

const Activities = () => {
	const [showTourDialog, setShowTourDialog] = useState(false)
	const { profile, activeGroup } = useUserContext()
	const createAttendance = useCreateAttendance()
	const deleteAttendance = useDeleteAttendance()
	const updateAttendance = useUpdateAttendance()
	const updateActivity = useUpdateActivity()
	const { setShowTopNav, setSettingsDisabled } =
		useOutletContext() as MainOutletContext

	useEffect(() => {
		setShowTopNav(true)
		setSettingsDisabled(false)
		if (localStorage.getItem(WELCOME_STEP_COOKIE)) setShowTourDialog(true)
	}, [])

	// LOAD ACTIVITIES INCLUDING JOINED ATTENDANCE & GUESTS TABLES
	const activities = useActivties({
		groupId: activeGroup?.id || '',
	})

	if (activities?.isError) {
		console.log(activities.error)
		throw new Error('Error loading joined activities')
	}
	if (!activities.data) return <></>

	// group joinedActivities by date such that we can map each day's activities
	const groupedActivities: GroupedActivities = activities.data.reduce(
		(acc, activity) => {
			const date = DateTime.fromISO(activity.datetime).toFormat(
				'yyyy-MM-dd'
			)
			if (!acc[date]) {
				acc[date] = []
			}
			acc[date].push(activity)
			return acc
		},
		{} as GroupedActivities
	)

	const startTour = () => {
		const tour = introJs()
		tour.onstart(() => {
			localStorage.removeItem(WELCOME_STEP_COOKIE)
			track('taketour-wizard')
			setShowTourDialog(false)
		})
		tour.start()
	}

	const handleAttendance = async (
		profileId: string,
		activityId: string,
		status: 'in' | 'out' | '',
		action: string
	) => {
		if (action === 'delete') {
			deleteAttendance.mutateAsync({ activityId, profileId }).then(() => {
				updateActivity
					.mutateAsync({
						activityId,
						activity: { updated_at: DateTime.now().toISO() },
					})
					.then(() => {
						queryClient.invalidateQueries({
							queryKey: getActivitiesQueryKey({
								groupId: activeGroup?.id || '',
							}),
						})
					})
			})
		} else if (action === 'update') {
			const newStatus: 'in' | 'out' = status === 'in' ? 'in' : 'out'
			updateAttendance
				.mutateAsync({ activityId, profileId, status: newStatus })
				.then(() => {
					updateActivity
						.mutateAsync({
							activityId,
							activity: { updated_at: DateTime.now().toISO() },
						})
						.then(() => {
							queryClient.invalidateQueries({
								queryKey: getActivitiesQueryKey({
									groupId: activeGroup?.id || '',
								}),
							})
						})
				})
		} else if (action === 'create') {
			const newStatus: 'in' | 'out' = status === 'in' ? 'in' : 'out'
			const attendance = {
				profile_id: profileId,
				activity_id: activityId,
				attendance_status: newStatus,
			}
			createAttendance.mutateAsync({ attendance }).then(() => {
				updateActivity
					.mutateAsync({
						activityId,
						activity: { updated_at: DateTime.now().toISO() },
					})
					.then(() => {
						queryClient.invalidateQueries({
							queryKey: getActivitiesQueryKey({
								groupId: activeGroup?.id || '',
							}),
						})
					})
			})
		} else {
			console.error(
				'Invalid action in JoinedActivities::HandleAttendance'
			)
		}
	}

	return (
		<>
			{/* LIST GROUPED ACTIVITIES */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'scroll',
					overflowX: 'hidden',
					paddingBottom: '80px',
				}}
			>
				{/* Map each day's activities */}
				{Object.keys(groupedActivities).length === 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							margin: '80px 40px',
						}}
					>
						<SvgIcon
							sx={{
								fontSize: '5.0rem',
								color: 'grey.700',
							}}
						>
							<CalendarTodayIcon />
						</SvgIcon>
						<Typography
							fontSize={'1.1rem'}
							fontWeight={'500'}
							textAlign={'center'}
							marginTop={'40px'}
							color={'grey.700'}
						>
							There are no activities for your group.
						</Typography>
						<Typography
							marginTop={'20px'}
							fontSize={'1.1rem'}
							fontWeight={'500'}
							textAlign={'center'}
							color={'grey.700'}
						>
							You can create one by tapping the button below.
						</Typography>
					</div>
				) : (
					Object.keys(groupedActivities).map((activityDate) => (
						<Box key={activityDate} sx={{ margin: '10px' }}>
							<Paper elevation={3} sx={{ paddingBottom: '5px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										overflow: 'hidden',
									}}
								>
									{/* ACTIVITY DATE */}
									<ActivityDateBox date={activityDate} />

									{/* ACTIVITY LIST */}
									<div
										style={{
											width: '100%',
											padding: '0px 5px',
										}}
									>
										{groupedActivities[activityDate].map(
											(activity) => (
												<ActivityInfoBox
													key={activity.id}
													activity={activity}
													profileId={
														profile?.id || ''
													}
													onAttendance={(
														profileId: string,
														activityId: string,
														status:
															| 'in'
															| 'out'
															| '',
														action: string
													) =>
														handleAttendance(
															profileId,
															activityId,
															status,
															action
														)
													}
												/>
											)
										)}
									</div>
								</div>
							</Paper>
						</Box>
					))
				)}
			</div>

			<Dialog open={showTourDialog}>
				<DialogTitle>Welcome to Joyner</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Tap Start to take a quick tour of Joyner&apos;s
						features.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={startTour} variant='contained'>
						Start
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Activities

import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
// import { getGeneric } from '../generic'
import queryClient from '../../lib/reactQuery'
import type { JoinedActivity } from '../../lib/supabase'
import type { QueryConfig } from '../../lib/reactQuery'

export const getQueryKey = ({ activityId }: GetActivityArgs) => [
	'activity',
	activityId,
	'joinedActivity',
]

type GetActivityArgs = {
	activityId: string
}
const getActivityFunction = async ({
	activityId,
}: GetActivityArgs): Promise<JoinedActivity> => {
	if (!activityId) return {} as JoinedActivity

	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.select(`*,activities_attendance(*),activities_guests(*)`)
		.order('datetime', { ascending: true })
		.eq('id', activityId)
		.single()

	if (error) {
		console.error(error)
		throw error
	}

	return data as JoinedActivity
}

export const getActivity = async ({ activityId }: GetActivityArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey({ activityId }),
		queryFn: () => getActivityFunction({ activityId }),
	})

type UseGetActivityOptions = {
	activityId: string
	options?: QueryConfig<typeof getActivityFunction>
}

export const useGetActivity = ({
	activityId,
	options,
}: UseGetActivityOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey({ activityId }),
		queryFn: async () => {
			const activity = await getActivityFunction({ activityId })
			return activity
		},
	})
}

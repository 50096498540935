import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import { TABLE_POLLS_CHOICES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { MutationConfig } from '../../lib/reactQuery'
import type { PollChoice } from '../../lib/supabase'

type DeleteChoiceArgs = {
	choiceId: string
}
export const deleteChoice = async ({ choiceId }: DeleteChoiceArgs) =>
	deleteGeneric({ tableName: TABLE_POLLS_CHOICES, id: choiceId })

type UseDeleteChoiceOptions = {
	options?: MutationConfig<typeof deleteChoice>
}
export const useDeleteChoice = ({ options }: UseDeleteChoiceOptions = {}) => {
	return useMutation({
		onMutate: async ({ choiceId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({ queryKey: ['choice', choiceId] })
			const previousChoice = queryClient.getQueryData<PollChoice>([
				'choice',
				choiceId,
			])

			return { previousChoice }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousChoice) {
				queryClient.setQueryData(
					['choice', context.previousChoice.id],
					context.previousChoice
				)
			}
		},
		onSuccess: (choiceId) => {
			queryClient.invalidateQueries({ queryKey: ['chocie', choiceId] })
			queryClient.invalidateQueries({ queryKey: ['choices'] })
		},
		...options,
		mutationFn: deleteChoice,
	})
}

import {
	useGetConversations,
	getConversationsQueryKey,
} from 'api/conversations'
import queryClient from 'lib/reactQuery'
import supabase, {
	TABLE_CHAT_CONVERSATIONS,
	type Conversation,
} from 'lib/supabase'
import { useEffect } from 'react'
import { useUserContext } from 'contexts/UserContext'

type Args = {
	groupId: string
}

const useConversations = ({ groupId }: Args = { groupId: '' }) => {
	const conversations = useGetConversations({
		groupId,
	})
	const { activeGroup } = useUserContext()

	useEffect(() => {
		const sub = supabase
			.channel('conversations-db-changes')
			.on(
				'postgres_changes',
				{
					event: '*',
					schema: 'public',
					table: TABLE_CHAT_CONVERSATIONS,
				},
				async (payload) => {
					console.log('useConverations::Payload::', payload.eventType)

					if (payload.eventType === 'DELETE') {
						queryClient.removeQueries({
							queryKey: getConversationsQueryKey({
								groupId: (payload.old as Conversation).group_id,
							}),
						})
						await queryClient.invalidateQueries({
							queryKey: getConversationsQueryKey({
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'UPDATE' &&
						conversations.data?.findIndex(
							(a) => a.id === (payload.new as Conversation).id
						) !== -1
					) {
						await queryClient.setQueryData(
							getConversationsQueryKey({
								groupId: (payload.new as Conversation).group_id,
							}),
							payload.new
						)
						await queryClient.invalidateQueries({
							queryKey: getConversationsQueryKey({
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'INSERT' &&
						(payload.new as Conversation).group_id ===
							activeGroup?.id
					) {
						await queryClient.setQueryData(
							getConversationsQueryKey({
								groupId: (payload.new as Conversation).group_id,
							}),
							payload.new
						)
						await queryClient.refetchQueries({
							queryKey: getConversationsQueryKey({
								groupId,
							}),
						})
					}
				}
			)
			.subscribe((status, err) => {
				if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
					if (err) console.error(err)
					sub.unsubscribe()
				}
			})

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, conversations.data])

	return conversations
}

export default useConversations

import React from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

export default function PageNotFound() {
	const navigate = useNavigate()
	const location = useLocation()
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '40px',
				gap: '20px',
			}}
		>
			<Typography fontSize={'1.0rem'} fontWeight={'500'}>
				Page not found
			</Typography>
			<Button variant='contained' onClick={() => navigate(-1)}>
				Go Back
			</Button>
			<Typography fontSize={'0.8rem'} color={'grey'}>
				{location.pathname}
			</Typography>
		</div>
	)
}

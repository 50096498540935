import React, { useState, useEffect } from 'react'
import { Box, Button, Stack } from '@mui/material'
import { type Group, type GroupInvite, type Profile } from 'lib/supabase'
import { useCreateInvite } from 'api/invites/create'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { v4 as uuidV4 } from 'uuid'
import { DateTime } from 'luxon'
import { pluralize } from 'utils'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

type Props = {
	group?: Group
	profile?: Profile
}
const InviteLink: React.FC<Props> = ({ group, profile }) => {
	const createInvite = useCreateInvite()
	const [inviteLink, setInviteLink] = useState<string>()
	const { showSnackbar } = useSnackbarContext()

	useEffect(() => {
		if (inviteLink) {
			handleCopyInvite()
		}
	}, [inviteLink])

	const handleCreateInviteLink = async () => {
		if (!profile || !group?.id) return

		try {
			const inviteId = uuidV4()
			const newInvite: GroupInvite = {
				id: inviteId,
				created_by: profile.id,
				group_id: group?.id,
				created_at: DateTime.now().toISO(),
				expires_at: DateTime.now().plus({ days: 7 }).toISO(),
				created_by_name: profile.full_name,
				group_name: group.name,
			}

			await createInvite.mutateAsync({
				invite: newInvite,
			})

			let inviteLink: string
			if (import.meta.env.DEV) {
				inviteLink = `http://${location.hostname}:${location.port}/group/join/${inviteId}`
			} else {
				inviteLink = `https://${location.hostname}/group/join/${inviteId}`
			}

			setInviteLink(inviteLink)
			showSnackbar('Invite link created', 'success')
		} catch (err) {
			showSnackbar('Error creating invite link', 'error')
		}
	}

	const handleCopyInvite = () => {
		if (!inviteLink) return

		console.log('HandleCopyInvite')

		try {
			const activityName = group?.activity_name
				? group.activity_name
				: 'Activity'
			const msg =
				'Hey Everyone, \n\n' +
				'We will be using Joyner to arrange our ' +
				pluralize(activityName).toLowerCase() +
				' for our group ' +
				group?.name +
				'.\n\n' +
				'Joyner is free for you to use as a group member, so there is no cost to you.\n\n' +
				"Joyner makes it simple for everyone in the group to know what's going on.\n\n" +
				'To join the group, click the link below:\n' +
				inviteLink +
				'\n\n' +
				'This link is valid for 7 days, so please join the group as soon as possible.  Please do not share this link with anyone else.' +
				'\n\n' +
				'For assistance accepting the invite and getting started with Joyner, visit the help site:\n' +
				'Accept Invite Help: https://help.joyner.app/guides/08-join-group\n' +
				'Dashboard Overview: https://help.joyner.app/reference/04-dashboard-overview\n' +
				'\n' +
				'Thanks,\n' +
				profile?.full_name +
				'\n\n' +
				'P.S.  For more info about Joyner go to:\n' +
				'https://www.joyner.app' +
				'\n\n'

			// this won't work on mobile without an https connection
			navigator.clipboard.writeText(msg)
			showSnackbar('Invite link copied to clipboard', 'info')
		} catch (err) {
			showSnackbar('Error copying invite link to clipboard', 'error')
		}
	}

	return (
		<>
			{/* INVITE BY LINK */}
			<Box
				style={{
					paddingTop: '10px',
					marginBottom: '10px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Stack direction={'row'} gap={1}>
					<Button
						variant='contained'
						disabled={inviteLink != null}
						onClick={handleCreateInviteLink}
					>
						Create invite link
					</Button>
					<Button
						variant='contained'
						disabled={inviteLink == null}
						color='success'
						startIcon={<ContentCopyIcon />}
						onClick={handleCopyInvite}
					>
						Copy
					</Button>
				</Stack>
			</Box>
		</>
	)
}

export default InviteLink

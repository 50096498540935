import { useMutation } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'
import { TABLE_ACTIVITIES_GUESTS } from 'lib/supabase'
import { deleteGeneric } from '../generic'
import type { MutationConfig } from '../../lib/reactQuery'
import type { ActivityGuest } from '../../lib/supabase'

type DeleteGuestArgs = {
	profileId: string
}
export const deleteGuest = async ({ profileId }: DeleteGuestArgs) =>
	deleteGeneric({ tableName: TABLE_ACTIVITIES_GUESTS, id: profileId })

type UseDeleteGuestOptions = {
	options?: MutationConfig<typeof deleteGuest>
}
export const useDeleteGuest = ({ options }: UseDeleteGuestOptions = {}) => {
	return useMutation({
		onMutate: async ({ profileId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({
				queryKey: ['guest', profileId],
			})
			const previousProfile = queryClient.getQueryData<ActivityGuest>([
				'guest',
				profileId,
			])

			return { profileId, previousProfile }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousProfile) {
				queryClient.setQueryData(
					['guest', context.profileId],
					context.previousProfile
				)
			}
		},
		onSuccess: (profileId) => {
			queryClient.invalidateQueries({ queryKey: ['guest', profileId] })
		},
		...options,
		mutationFn: deleteGuest,
	})
}

import queryClient from '../../lib/reactQuery'
import { createGeneric } from '../generic'
import { TABLE_GROUPS_VENUES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { GroupVenue } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateVenueArgs = {
	venue: GroupVenue
}
export const createVenue = async ({ venue }: CreateVenueArgs) =>
	createGeneric({ tableName: TABLE_GROUPS_VENUES, data: venue })

type UseCreateVenueOptions = {
	options?: MutationConfig<typeof createVenue>
}
export const useCreateVenue = ({ options }: UseCreateVenueOptions = {}) => {
	return useMutation({
		onSuccess: (venue) => {
			if ('id' in venue) {
				queryClient.setQueryData(['venue', venue.id], venue)
				queryClient.invalidateQueries({ queryKey: ['venues'] })
			}
		},
		...options,
		mutationFn: createVenue,
	})
}

import React, { useEffect, useState } from 'react'
import { Box, AppBar, Button } from '@mui/material'
import { useNavigate, useParams, useLocation } from 'react-router'
import { useGetVenue, useUpdateVenue, useCreateVenue } from 'api/venue'
import { useUserContext } from 'contexts/UserContext'
import Loading from 'components/Loading'
import type { GroupVenue } from 'lib/supabase'
import ViewVenue from './ViewVenue'
import EditVenue from './EditVenue'

const VenuePage: React.FC = () => {
	const [editVenue, setEditVenue] = useState(false)
	const [newVenue, setNewVenue] = useState(false)
	// const [venue, setVenue] = useState<Venue | null>(null)
	const [loading, setLoading] = useState(true)
	const { profile } = useUserContext()
	const updateVenue = useUpdateVenue()
	const createVenue = useCreateVenue()
	const navigate = useNavigate()

	const { venueId } = useParams()
	const { edit } = useParams()

	const { data: venue, error } = useGetVenue({
		venueId: venueId || '',
		options: { enabled: !!venueId },
	})

	useEffect(() => {
		if (venueId === 'new') {
			console.log('Handle creating new venue')
			setNewVenue(true)
		}
	}, [venueId])

	useEffect(() => {
		if (edit) {
			console.log('Handle editing venue from route')
			setEditVenue(true)
		}
	}, [edit])

	const handleCancel = () => {
		if (edit) {
			navigate(`/activity/${edit}`, { state: { backPath: '/' } })
		} else {
			setEditVenue(false)
		}
	}

	const handleSave = (newVenue: GroupVenue) => {
		console.log('VenuePage::handleSave', JSON.stringify(newVenue))
		updateVenue.mutate({ venue: newVenue })
		if (edit) {
			navigate(`/activity/${edit}`, { state: { backPath: '/' } })
		} else {
			setEditVenue(false)
		}
	}

	const handleCreateVenue = (newVenue: GroupVenue) => {
		console.log('VenuePage::handleCreateVenue', JSON.stringify(newVenue))
		createVenue.mutate({ venue: newVenue })
		setNewVenue(false)
		navigate(`/venue/${newVenue.id}`)
	}

	const handleEdit = () => {
		setEditVenue(true)
	}

	return (
		<div>
			<Box component={'main'}>
				{venue ? (
					editVenue ? (
						<EditVenue
							venue={venue}
							onSave={handleSave}
							onCancel={handleCancel}
							onCreateVenue={handleCreateVenue}
						/>
					) : (
						<ViewVenue
							venue={venue}
							onEdit={handleEdit}
							onBack={() =>
								navigate(
									`/group/${profile?.active_group}/venues`,
									{ state: { backPath: '/' } }
								)
							}
						/>
					)
				) : newVenue ? (
					<EditVenue
						venue={{
							id: 'new',
							created_at: '',
							updated_at: null,
							group_id: profile?.active_group || '',
							name: '',
							phone: '',
							url: '',
							street: '',
							city: '',
							state: '',
							zip: '',
						}}
						onSave={handleSave}
						onCancel={handleCancel}
						onCreateVenue={handleCreateVenue}
					/>
				) : (
					<div>Error loading venue</div>
				)}
			</Box>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
					display: editVenue ? 'none' : newVenue ? 'none' : 'block',
				}}
			>
				<div
					style={{
						display: newVenue ? 'none' : 'flex',
						flexDirection: 'column',
						gap: '10px',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '10px',
					}}
				>
					<Button
						fullWidth
						variant='contained'
						style={{
							maxWidth: '300px',
						}}
						onClick={handleEdit}
					>
						Edit Venue
					</Button>
				</div>
			</AppBar>
		</div>
	)
}
export default VenuePage

import React, { useState } from 'react'
import { Box, Backdrop, CircularProgress, Typography } from '@mui/material'
import EditProfile from './EditProfile'
import EditAvatar from './EditAvatar'
import ViewProfile from './ViewProfile'
import type { Profile } from 'lib/supabase'
import { useUserContext } from 'contexts/UserContext'
import Loading from 'components/Loading'
import { useGetProfile, useUpdateProfile } from 'api/profile'
import { useNavigate } from 'react-router'

const Profile: React.FC = () => {
	const [editing, setEditing] = useState(false)
	const [editingAvatar, setEditingAvatar] = useState(false)
	const [waiting, setWaiting] = useState(false)
	const { user } = useUserContext()
	const profile = useGetProfile({
		profileId: user!.id,
		options: { enabled: !!user },
	})
	const updateProfile = useUpdateProfile()
	const navigate = useNavigate()

	const handleWaiting = (val: boolean) => {
		setWaiting(val)
	}

	const handleEdit = () => {
		setEditing(true)
	}

	const handleAvatarEdit = () => {
		setEditingAvatar(true)
	}

	const handleCancel = () => {
		setEditing(false)
		setEditingAvatar(false)
	}

	const handleSave = (newProfile: Partial<Omit<Profile, 'id'>>) => {
		if (profile.isSuccess) {
			updateProfile.mutate({
				profileId: profile.data.id,
				profile: newProfile,
			})
		} else {
			console.warn('Profile is not loaded yet')
		}
		setEditing(false)
	}

	const handleSaveAvatar = () => {
		setEditingAvatar(false)
	}

	return (
		<Box component={'main'}>
			{profile.isSuccess ? (
				<>
					{editing ? (
						<EditProfile
							profile={profile.data}
							onSave={handleSave}
							onCancel={handleCancel}
						/>
					) : editingAvatar ? (
						<EditAvatar
							profile={profile.data}
							onSave={handleSaveAvatar}
							onCancel={handleCancel}
							onSetWaiting={(val: boolean) => handleWaiting(val)}
						/>
					) : (
						<ViewProfile
							profile={profile.data}
							onBack={() => navigate(-1)}
							onEdit={handleEdit}
							onAvatarEdit={handleAvatarEdit}
						/>
					)}
				</>
			) : (
				<Loading />
			)}
			<Backdrop
				sx={{
					backgroundColor: 'rgba(255, 255, 255, 0)',
					zIndex: 1001,
				}}
				open={waiting}
			>
				<div
					style={{
						borderRadius: '20px',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						padding: '20px',
						color: '#ffffff',
						width: '200px',
						height: '200px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<CircularProgress color='inherit' />
					<Typography
						fontSize={'1.0rem'}
						fontWeight={'bold'}
						paddingTop={'20px'}
						color='inherit'
					>
						Updating avatar...
					</Typography>
				</div>
			</Backdrop>
		</Box>
	)
}

export default Profile

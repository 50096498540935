import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import { TABLE_GROUPS_VENUES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { MutationConfig } from '../../lib/reactQuery'
import type { GroupVenue } from '../../lib/supabase'

type DeleteVenueArgs = {
	venueId: string
}
export const deleteVenue = async ({ venueId }: DeleteVenueArgs) =>
	deleteGeneric({ tableName: TABLE_GROUPS_VENUES, id: venueId })

type UseDeleteVenueOptions = {
	options?: MutationConfig<typeof deleteVenue>
}
export const useDeleteVenue = ({ options }: UseDeleteVenueOptions = {}) => {
	return useMutation({
		onMutate: async ({ venueId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({ queryKey: ['venue', venueId] })
			const previousVenue = queryClient.getQueryData<GroupVenue>([
				'venue',
				venueId,
			])

			return { previousVenue }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousVenue) {
				queryClient.setQueryData(
					['venue', context.previousVenue.id],
					context.previousVenue
				)
			}
		},
		onSuccess: (venueId) => {
			queryClient.invalidateQueries({ queryKey: ['venue', venueId] })
			queryClient.invalidateQueries({ queryKey: ['venues'] })
		},
		...options,
		mutationFn: deleteVenue,
	})
}

import { Conversation, TABLE_CHAT_CONVERSATIONS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import type { MutationConfig } from '../../lib/reactQuery'

type DeleteConversationArgs = {
	chatId: string
}
export const deleteConversation = async ({
	chatId,
}: DeleteConversationArgs) => {
	deleteGeneric({ tableName: TABLE_CHAT_CONVERSATIONS, id: chatId })
}

type UseDeleteConversationOptions = {
	options?: MutationConfig<typeof deleteConversation>
}
export const useDeleteConversation = ({
	options,
}: UseDeleteConversationOptions = {}) => {
	return useMutation({
		onMutate: async ({ chatId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({
				queryKey: ['chat', chatId],
			})
			// const previousChat = queryClient.getQueryData<Conversation>([
			// 	'chat',
			// 	chatId,
			// ])
			// return { previousChat }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// onError: (_, __, context: any) => {
		// 	// revert the cache back to the previous value
		// 	// if (context?.previousChat) {
		// 	// 	queryClient.setQueryData(
		// 	// 		['message', context.previousChat.id],
		// 	// 		context.previousChat
		// 	// 	)
		// 	// }
		// },
		onSuccess: (chatId) => {
			queryClient.invalidateQueries({
				queryKey: ['chat', chatId],
			})
			// // don't have access to the week so we just invalidate ever week
			// queryClient.invalidateQueries({ queryKey: ['messages'] })
		},
		...options,
		mutationFn: deleteConversation,
	})
}

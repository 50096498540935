import { useInfiniteQuery } from '@tanstack/react-query'
import type { InfiniteQueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_CHAT_MESSAGES } from 'lib/supabase'

const MESSAGE_FETCH_LIMIT = 20

// keep the messages between groups and activities separate
export const getInfiniteQueryKeyByChatId = (chatId: string) => [
	'chat',
	chatId,
	'messages',
]

type PageParams = number

type FetchMessagesArgs = {
	pageParam: PageParams
	chatId: string
}
const fetchMessages = async ({ pageParam = 0, chatId }: FetchMessagesArgs) => {
	const { data, error } = await supabase
		.from(TABLE_CHAT_MESSAGES)
		.select()
		.eq('chat_id', chatId)
		.order('created_at', { ascending: false })
		.range(
			pageParam * MESSAGE_FETCH_LIMIT,
			(pageParam + 1) * MESSAGE_FETCH_LIMIT - 1
		)

	if (error) throw error

	return data
}

type getInfiniteByChatIdArgs = {
	chatId: string
	options?: InfiniteQueryConfig<typeof fetchMessages, PageParams>
}
export const getInfiniteByChatId = ({
	chatId,
	options,
}: getInfiniteByChatIdArgs) => {
	return useInfiniteQuery({
		...options,
		queryKey: getInfiniteQueryKeyByChatId(chatId),
		queryFn: ({ pageParam }) => fetchMessages({ pageParam, chatId }),
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			return lastPage.length ? lastPageParam + 1 : undefined
		},
	})
}

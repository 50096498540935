import { TABLE_CHAT_MESSAGES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import type { MutationConfig } from '../../lib/reactQuery'
import type { Message } from '../../lib/supabase'

type DeleteMessageArgs = {
	messageId: string
}
export const deleteMessage = async ({ messageId }: DeleteMessageArgs) => {
	console.log('deleteMessage called for messageId:', messageId)
	deleteGeneric({ tableName: TABLE_CHAT_MESSAGES, id: messageId })
}

type UseDeleteMessageOptions = {
	options?: MutationConfig<typeof deleteMessage>
}
export const useDeleteMessage = ({ options }: UseDeleteMessageOptions = {}) => {
	return useMutation({
		onMutate: async ({ messageId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({
				queryKey: ['message', messageId],
			})
			const previousMessage = queryClient.getQueryData<Message>([
				'message',
				messageId,
			])

			return { previousMessage }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousMessage) {
				queryClient.setQueryData(
					['message', context.previousMessage.id],
					context.previousMessage
				)
			}
		},
		onSuccess: (messageId) => {
			queryClient.invalidateQueries({
				queryKey: ['message', messageId],
			})

			// don't have access to the week so we just invalidate ever week
			queryClient.invalidateQueries({ queryKey: ['messages'] })
		},
		...options,
		mutationFn: deleteMessage,
	})
}

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, Stack } from '@mui/material'

import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	MouseSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core'
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'

const DND = () => {
	const navigate = useNavigate()
	const [rows, setRows] = useState(['1', '2', '3', '4', '5', '6'])
	const sensors = useSensors(
		useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		}),
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event

		if (active && over && active.id !== over.id) {
			setRows((items) => {
				const oldIndex = items.indexOf(active.id.toString())
				const newIndex = items.indexOf(over.id.toString())

				return arrayMove(items, oldIndex, newIndex)
			})
		}
	}

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate('/prototypes')}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'DND Prototype'}
			/>
			<div style={{ margin: '20px' }}>
				<Stack direction='column' spacing={1}>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={handleDragEnd}
					>
						<SortableContext
							items={rows}
							strategy={verticalListSortingStrategy}
						>
							{rows.map((row, index) => (
								<SortableItem key={index} id={row} />
							))}
						</SortableContext>
					</DndContext>
				</Stack>
			</div>
		</>
	)
}

export default DND

import React from 'react'
import { DateTime } from 'luxon'
import { Typography } from '@mui/material'

type Props = {
	date: string
}

const ActivityDateBox: React.FC<Props> = ({ date }) => {
	return (
		<div
			style={{
				minWidth: '6.5rem',
				paddingTop: '20px',
				borderRight: '1px solid #dddddd',
				textAlign: 'center',
			}}
		>
			<Typography fontWeight={'bold'}>
				{DateTime.fromISO(date).toFormat('cccc')}
			</Typography>
			<Typography fontWeight={'bold'} fontSize={'2.0rem'}>
				{DateTime.fromISO(date).toFormat('d')}
			</Typography>
			<Typography>{DateTime.fromISO(date).toFormat('LLLL')}</Typography>
		</div>
	)
}

export default ActivityDateBox

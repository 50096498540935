import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { Message, Conversation } from '../lib/supabase'
import { useUserContext } from './UserContext'
import useConversations from '../hooks/useConversations'

type AlertsContextType = {
	showMessagesBadge: boolean
	conversationState: ConversationState[]
	onReset: () => void
	onRefresh: () => void
	updateConversationState: (conversationId: string) => Promise<void>
}

type ConversationState = {
	conversationId: string
	viewed: boolean
}

const AlertsContext = React.createContext<AlertsContextType>({
	showMessagesBadge: false,
	conversationState: [],
	onReset: () => {},
	onRefresh: () => {},
	updateConversationState: () => Promise.resolve(),
})

export const useAlertsContext = () => React.useContext(AlertsContext)

export const AlertsContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { profile, prevLastAccess, activeGroup } = useUserContext()
	const [showMessagesBadge, setShowMessageBadge] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)
	const [conversationState, setConversationState] = useState<
		ConversationState[]
	>([])
	const conversations = useConversations({
		groupId: activeGroup?.id || '',
	}).data as Conversation[]

	useEffect(() => {
		if (conversations && Array.isArray(conversations)) {
			const newConversationState = conversations.map((conversation) => ({
				conversationId: conversation.id,
				viewed: false,
			}))
			setConversationState(newConversationState)
		}
	}, [conversations])

	useEffect(() => {
		const checkForNewMessages = async () => {
			if (conversations && Array.isArray(conversations)) {
				const lastAccess = DateTime.fromISO(prevLastAccess || '')
				const hasNewMessages = conversations.some(
					(conversation: Conversation) => {
						const updatedAt = DateTime.fromISO(
							conversation.updated_at || ''
						)
						if (
							profile?.id !== conversation.last_sender_id &&
							updatedAt > lastAccess
						) {
							return true
						} else {
							return false
						}
					}
				)
				setShowMessageBadge(hasNewMessages)
			}
		}

		checkForNewMessages()
	}, [conversations, prevLastAccess])

	const handleChangeConversationState = (
		conversationId: string
	): Promise<void> => {
		return new Promise((resolve) => {
			setConversationState((prevState) => {
				const index = prevState.findIndex(
					(state) => state.conversationId === conversationId
				)
				if (index !== -1) {
					const newState = [...prevState]
					newState[index].viewed = true
					resolve()
					return newState
				} else {
					resolve()
					return [...prevState, { conversationId, viewed: true }]
				}
			})
			setShowMessageBadge(false)
		})
	}

	return (
		<AlertsContext.Provider
			value={{
				showMessagesBadge,
				conversationState,
				onReset: () => setShowMessageBadge(false),
				onRefresh: () => setForceRefresh(!forceRefresh),
				updateConversationState: (conversationId: string) =>
					handleChangeConversationState(conversationId),
			}}
		>
			{children}
		</AlertsContext.Provider>
	)
}

import supabase, { TABLE_ACTIVITIES_ATTENDANCE } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'
import type { MutationConfig } from '../../lib/reactQuery'
import type { ActivityAttendance } from '../../lib/supabase'

type DeleteAttendanceArgs = {
	profileId: string
	activityId: string
}
export const deleteAttendance = async ({
	activityId,
	profileId,
}: DeleteAttendanceArgs) =>
	await supabase
		.from(TABLE_ACTIVITIES_ATTENDANCE)
		.delete()
		.eq('profile_id', profileId)
		.eq('activity_id', activityId)

type UseDeleteAttendanceOptions = {
	options?: MutationConfig<typeof deleteAttendance>
}
export const useDeleteAttendance = ({
	options,
}: UseDeleteAttendanceOptions = {}) => {
	return useMutation({
		onMutate: async ({ activityId, profileId }: DeleteAttendanceArgs) => {
			await queryClient.cancelQueries({
				queryKey: ['activity', activityId, 'profile', profileId],
			})
			const previousAttendance =
				queryClient.getQueryData<ActivityAttendance>([
					'activity',
					activityId,
					'profile',
					profileId,
				])

			return { previousAttendance }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			if (context?.previousAttendance) {
				queryClient.setQueryData(
					[
						'activity',
						context.previousActivity.id,
						'profile',
						context.previousAttendance.profile_id,
					],
					context.previousAttendance
				)
			}
		},
		onSuccess: (activityId, profileId) => {
			queryClient.invalidateQueries({
				queryKey: ['activity', activityId, 'profile', profileId],
			})

			// don't have access to the week so we just invalidate ever week
			queryClient.invalidateQueries({ queryKey: ['activities'] })
		},
		...options,
		mutationFn: deleteAttendance,
	})
}

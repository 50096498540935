import React from 'react'
import { Typography, Button } from '@mui/material'
import { useUserContext } from 'contexts/UserContext'
import { useSubscriptionContext } from 'contexts/SubscriptionContext'
import { useNavigate } from 'react-router'

const NoGroups: React.FC = () => {
	const { profile } = useUserContext()
	const { subscriptionStatus, hasSubscription } = useSubscriptionContext()
	const navigate = useNavigate()

	const handleSubscribe = () => {
		navigate('/account')
	}

	const handleCreateGroup = () => {
		navigate('/groups')
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: '20px 40px',
			}}
		>
			<Typography
				textAlign={'center'}
				marginBottom={2}
				marginTop={2}
				fontWeight={'bold'}
				fontSize={'1.1rem'}
			>
				You don&apos;t currently belong to any groups.
			</Typography>
			{subscriptionStatus === 'inactive' ? (
				<>
					<Typography textAlign={'center'} marginBottom={2}>
						If you would like to create your own groups, start your
						90 day <strong>FREE</strong> trial.
					</Typography>
					<Button
						onClick={handleSubscribe}
						variant='contained'
						color='success'
					>
						Start FREE Trial
					</Button>
				</>
			) : (
				<>
					<Typography textAlign={'center'} marginBottom={2}>
						You already have a Joyner subscription however, so you
						can create your own groups and invite your friends who
						use Joyner for <strong>FREE</strong>.
					</Typography>
					<Button onClick={handleCreateGroup} variant='contained'>
						Create Group
					</Button>
				</>
			)}
			<Typography marginTop={6} fontStyle={'italic'}>
				If you think you should be a member of a group, please contact
				the group admin and ask them to send you the invite link to join
				the group.
			</Typography>
		</div>
	)
}

export default NoGroups

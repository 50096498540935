import React, { useEffect } from 'react'
import queryClient from 'lib/reactQuery'
import supabase, { TABLE_ACTIVITIES, type JoinedActivity } from 'lib/supabase'
import {
	useGetActivities,
	getActivitiesQueryKey,
} from 'api/activity/getActivitiesByGroupId'
import { getQueryKey } from 'api/activity'

type Args = {
	groupId: string
}

const useActivities = ({ groupId }: Args) => {
	const activities = useGetActivities({
		groupId,
	})

	useEffect(() => {
		const sub = supabase
			.channel('db-changes')
			.on(
				'postgres_changes',
				{
					event: '*',
					schema: 'public',
					table: TABLE_ACTIVITIES,
				},
				async (payload) => {
					if (payload.eventType === 'DELETE') {
						queryClient.removeQueries({
							queryKey: getQueryKey({
								activityId: (payload.old as JoinedActivity).id,
							}),
						})
						await queryClient.invalidateQueries({
							queryKey: getActivitiesQueryKey({
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'UPDATE' &&
						activities.data?.findIndex(
							(a) => a.id === (payload.new as JoinedActivity).id
						) !== -1
					) {
						// Get the existing cached activity data
						const existingActivity =
							queryClient.getQueryData<JoinedActivity>(
								getQueryKey({
									activityId: (payload.new as JoinedActivity)
										.id,
								})
							)
						console.log(
							'useActivities::existingActivity',
							JSON.stringify(existingActivity)
						)

						// Merge the updated fields with the existing cached data
						const mergedActivity = {
							...existingActivity,
							...payload.new,
						}

						// Update the cache with the merged activity data
						await queryClient.setQueryData(
							getQueryKey({
								activityId: (payload.new as JoinedActivity).id,
							}),
							mergedActivity
						)

						// Invalidate the group activities query to ensure consistency
						await queryClient.invalidateQueries({
							queryKey: getActivitiesQueryKey({
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'INSERT' &&
						(payload.new as JoinedActivity).group_id === groupId
					) {
						await queryClient.setQueryData(
							getQueryKey({
								activityId: (payload.new as JoinedActivity).id,
							}),
							payload.new
						)
						await queryClient.refetchQueries({
							queryKey: getActivitiesQueryKey({
								groupId,
							}),
						})
					}
				}
			)
			.subscribe((status, err) => {
				if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
					if (err) console.error(err)
					sub.unsubscribe()
				}
			})

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, activities.data])

	return activities
}

export default useActivities

import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { SentryRoutes } from 'lib/sentry'
import Login from 'pages/Login/Login'
import OTPChallengePage from 'pages/Login/OTPChallengePage'
import CreateAccount from 'pages/Login/CreateAccount'
import ProtectedRoute from 'components/ProtectedRoute'
import MainLayout from 'pages/Main/MainLayout'
import Activities from 'pages/Activity/Activities'
import Account from 'pages/Account/Account'
import Support from 'pages/Support'
import Purchase from 'pages/Account/Purchase'
import ActivityPage from 'pages/Activity/ActivityPage'
import GroupList from 'pages/Group/GroupList'
import GroupPage from 'pages/Group/GroupPage'
import Conversations from 'pages/Conversations/ConversationsPage'
import Polls from 'pages/Polls/PollsList'
import PollPage from 'pages/Polls/PollPage'
import PageNotFound from 'pages/PageNotFound'
import UserProfile from 'pages/Profile/UserProfile'
import VenueList from 'pages/Group/VenuesTab'
import Venue from 'pages/Venue/VenuePage'
import MessagesPage from 'pages/Conversations/MessagesPage'
import Invite from 'pages/Invite/InvitePage'
import CreatePage from 'pages/Main/CreatePage'
import Prototypes from 'pages/Prototypes/Prototypes'
import AIX from 'pages/Prototypes/AIX'
import RTE from 'pages/Prototypes/RTE'
import DND from 'pages/Prototypes/DND'
import Events from 'pages/Prototypes/Events'

const AppRoutes: React.FC = () => (
	<BrowserRouter>
		<SentryRoutes>
			<Route path='/login' element={<Login />} />
			<Route path='/otp/:challengeId' element={<OTPChallengePage />} />
			<Route path='/create' element={<CreateAccount />} />
			<Route path='group/join/:inviteId' element={<Invite />} />
			{/* below are the protected routes that require logging in */}
			<Route path='/' element={<ProtectedRoute />}>
				<Route path='' element={<MainLayout />}>
					<Route path='' element={<Activities />} />
					<Route path='polls' element={<Polls />} />
					<Route path='conversations' element={<Conversations />} />
				</Route>
				<Route path='create/:createType' element={<CreatePage />} />
				<Route path='poll/:pollId' element={<PollPage />} />
				<Route path='activity/:activityId' element={<ActivityPage />} />
				<Route path='profile/:userId' element={<UserProfile />} />
				<Route path='messages/:chatId' element={<MessagesPage />} />
				<Route path='venues' element={<VenueList />} />
				<Route path='venue' element={<Venue />} />
				<Route path='venue/:venueId' element={<Venue />} />
				<Route path='venue/:venueId/:edit' element={<Venue />} />
				<Route path='groups' element={<GroupList />} />
				<Route path='group/:groupId' element={<GroupPage />} />
				<Route
					path='group/:groupId/:tabToOpen/'
					element={<GroupPage />}
				/>
				<Route path='account' element={<Account />} />
				<Route path='support' element={<Support />} />
				<Route path='purchase' element={<Purchase />} />
				<Route path='prototypes' element={<Prototypes />} />
				<Route path='aix' element={<AIX />} />
				<Route path='rte' element={<RTE />} />
				<Route path='dnd' element={<DND />} />
				<Route path='events' element={<Events />} />
			</Route>
			<Route path='*' element={<PageNotFound />} />
		</SentryRoutes>
	</BrowserRouter>
)

export default AppRoutes

import React, { useState, useEffect, useCallback } from 'react'
import { IconButton, Button, ButtonGroup, Stack, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useEditor, EditorContent, Editor } from '@tiptap/react'
import DOMPurify from 'dompurify'
import { useCreateEventTextBlock } from 'api/event/create'
import { useGetEventTextBlock } from 'api/event/get'
import { useUpdateEventTextBlock } from 'api/event/update'
import { v4 as UUIDV4 } from 'uuid'
import { DateTime } from 'luxon'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

// TipTip imports
import './style.css'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import History from '@tiptap/extension-history'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import Italic from '@tiptap/extension-italic'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Heading from '@tiptap/extension-heading'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FormatBoldSharpIcon from '@mui/icons-material/FormatBoldSharp'
import FormatItalicSharpIcon from '@mui/icons-material/FormatItalicSharp'
import FormatUnderlinedSharpIcon from '@mui/icons-material/FormatUnderlinedSharp'
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp'
import FormatListNumberedSharpIcon from '@mui/icons-material/FormatListNumberedSharp'
import FormatSizeSharpIcon from '@mui/icons-material/FormatSizeSharp'

const RTE = () => {
	const navigate = useNavigate()
	const [editing, setEditing] = useState(false)
	const [content, setContent] = useState('')
	const createEventTextBlock = useCreateEventTextBlock()
	const textBlock = useGetEventTextBlock({ textblockId: '*' })
	const updateEventTextBlock = useUpdateEventTextBlock()

	const extensions = [
		Document,
		History,
		Paragraph,
		Text,
		Bold,
		Underline,
		Italic,
		BulletList.configure({
			itemTypeName: 'listItem',
		}),
		OrderedList.configure({
			itemTypeName: 'listItem',
		}),
		ListItem,
		Heading.configure({ levels: [1, 2, 3] }),
		TextStyle.configure({ mergeNestedSpanStyles: true }),
		Color,
	]
	const editor = useEditor({
		extensions: extensions,
	}) as Editor

	useEffect(() => {
		if (!content && textBlock.data?.text_block) {
			setContent(textBlock.data?.text_block || '')
		}
	}, [textBlock, content])

	const toggleBold = useCallback(() => {
		editor.chain().focus().toggleBold().run()
	}, [editor])

	const toggleUnderline = useCallback(() => {
		editor.chain().focus().toggleUnderline().run()
	}, [editor])

	const toggleItalic = useCallback(() => {
		editor.chain().focus().toggleItalic().run()
	}, [editor])

	const toggleBulletList = useCallback(() => {
		editor.chain().focus().toggleBulletList().run()
	}, [editor])

	const toggleOrderedList = useCallback(() => {
		editor.chain().focus().toggleOrderedList().run()
	}, [editor])

	const toggleHeading = useCallback(
		(level: number) => {
			editor
				.chain()
				.focus()
				.toggleHeading({ level: level as 1 | 2 | 3 })
				.run()
		},
		[editor]
	)

	const toggleColor = useCallback(
		(color: string) => {
			editor.chain().focus().setColor(color).run()
		},
		[editor]
	)

	const handleEdit = () => {
		editor.commands.setContent(content)
		setEditing(true)
	}

	const handleSave = () => {
		const dirty = editor.getHTML()
		const clean = DOMPurify.sanitize(dirty)

		if (textBlock.data?.id) {
			updateEventTextBlock
				.mutateAsync({
					textblockId: textBlock.data.id,
					textblock: {
						text_block: clean,
					},
				})
				.then(() => {
					setContent(clean)
					setEditing(false)
				})
		} else {
			console.log('TextBlock::Create')
			createEventTextBlock
				.mutateAsync({
					textblock: {
						created_at: DateTime.now().toISO(),
						id: UUIDV4(),
						text_block: clean,
						display_order: 0,
						event_id: null,
					},
				})
				.then(() => {
					setContent(clean)
					setEditing(false)
				})
		}
	}

	const getTextBlockDiv = useCallback(() => {
		return <div dangerouslySetInnerHTML={{ __html: content }} />
	}, [content])

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate('/prototypes')}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'RTE Prototype'}
			/>
			<div style={{ margin: '20px' }}>
				<Paper
					style={{
						padding: '5px 10px',
						marginBottom: '10px',
						position: 'relative',
						minHeight: '60px',
					}}
					elevation={3}
				>
					{getTextBlockDiv()}
					<Button
						style={{
							position: 'absolute',
							top: 15,
							right: 5,
						}}
						onClick={handleEdit}
					>
						edit
					</Button>
				</Paper>
				<Button
					variant='text'
					fullWidth
					startIcon={<AddCircleOutlineIcon />}
				>
					Add Section
				</Button>

				<div
					className='editor'
					style={{
						display: editing ? 'block' : 'none',
						marginTop: '20px',
					}}
				>
					<div
						className='menu'
						style={{ backgroundColor: '#f5f5f5' }}
					>
						<Stack direction='row' spacing={1}>
							<ButtonGroup variant='text'>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('bold'),
									})}
									onClick={toggleBold}
								>
									<FormatBoldSharpIcon />
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active':
											editor.isActive('underline'),
									})}
									onClick={toggleUnderline}
								>
									<FormatUnderlinedSharpIcon />
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('italic'),
									})}
									onClick={toggleItalic}
								>
									<FormatItalicSharpIcon />
								</Button>
							</ButtonGroup>

							<ButtonGroup variant='text'>
								<Button
									className={classNames('menu-button', {
										'is-active':
											editor.isActive('bulletList'),
									})}
									onClick={toggleBulletList}
								>
									<FormatListBulletedSharpIcon />
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active':
											editor.isActive('orderedList'),
									})}
									onClick={toggleOrderedList}
								>
									<FormatListNumberedSharpIcon />
								</Button>
							</ButtonGroup>

							<ButtonGroup variant='text'>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleHeading(1)}
								>
									H1
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleHeading(2)}
								>
									H2
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleHeading(3)}
								>
									H3
								</Button>
							</ButtonGroup>
						</Stack>

						<Stack
							direction='row'
							spacing={1}
							borderTop={'3px solid #ffffff'}
						>
							<ButtonGroup variant='text'>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleColor('black')}
								>
									<FormatSizeSharpIcon
										style={{ color: 'black' }}
									/>
								</Button>

								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleColor('red')}
								>
									<FormatSizeSharpIcon
										style={{ color: 'red' }}
									/>
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleColor('blue')}
								>
									<FormatSizeSharpIcon
										style={{ color: 'blue' }}
									/>
								</Button>
								<Button
									className={classNames('menu-button', {
										'is-active': editor.isActive('heading'),
									})}
									onClick={() => toggleColor('green')}
								>
									<FormatSizeSharpIcon
										style={{ color: 'green' }}
									/>
								</Button>
							</ButtonGroup>
						</Stack>
					</div>
					<EditorContent editor={editor} content={content} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							gap: '5px',
							marginTop: '10px',
						}}
					>
						<Button
							variant='text'
							onClick={() => setEditing(false)}
						>
							Cancel
						</Button>
						<Button variant='outlined'>Delete</Button>
						<Button variant='contained' onClick={handleSave}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export default RTE

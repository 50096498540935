import queryClient from '../../lib/reactQuery'
import { TABLE_ACTIVITIES_ATTENDANCE } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { ActivityAttendance } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type ActivityAttendanceArgs = {
	attendance: ActivityAttendance
}
export const createAttendance = async ({
	attendance,
}: ActivityAttendanceArgs) =>
	createGeneric({ tableName: TABLE_ACTIVITIES_ATTENDANCE, data: attendance })

type UseCreateAttendanceOptions = {
	options?: MutationConfig<typeof createAttendance>
}
export const useCreateAttendance = ({
	options,
}: UseCreateAttendanceOptions = {}) => {
	return useMutation({
		onSuccess: (_, attendance) => {
			queryClient.setQueryData(
				['attendance', attendance.attendance.activity_id],
				attendance.attendance
			)
		},
		...options,
		mutationFn: createAttendance,
	})
}

import React from 'react'
import { Typography, Stack, Icon } from '@mui/material'

type ReactionBubbleProps = {
	reactions: { [key: string]: number }
	onClick: () => void
}

const ReactionBubble: React.FC<ReactionBubbleProps> = ({
	reactions,
	onClick,
}) => {
	return (
		<>
			{reactions &&
				Object.keys(reactions).map((reaction) => (
					<div
						key={reaction}
						style={{
							position: 'relative',
							top: '-10px',
							backgroundColor: 'rgba(0, 0, 0, 0.6)',
							height: '32px',
							borderRadius: '10px',
							padding: '5px 10px 5px 5px',
							border: '1px solid rgba(0,0,0,0.2)',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
						onClick={onClick}
					>
						<Stack direction={'row'} alignItems={'center'} gap={1}>
							<Icon>
								<Typography>
									{reaction}
									{/* {String.fromCodePoint(parseInt(reaction))} */}
								</Typography>
							</Icon>
							<Typography
								fontWeight={'bold'}
								color={'#ffffff'}
								fontSize={'0.8rem'}
							>
								{reactions[reaction]}
							</Typography>
						</Stack>
					</div>
				))}
		</>
	)
}

export default ReactionBubble

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { FormInputText } from '../elements'

export type SignInFormValues = {
	phone: string
}
type Props = {
	defaultValues?: SignInFormValues
	style?: React.CSSProperties
	onSubmit: (values: SignInFormValues) => void | Promise<void>
}

const SignInForm: React.FC<Props> = ({ defaultValues, style, onSubmit }) => {
	const { control, handleSubmit } = useForm<SignInFormValues>({
		resolver: yupResolver(
			object().shape({
				phone: Validators.PHONE.required(),
			})
		),
		defaultValues: defaultValues || {
			phone: '',
		},
	})

	return (
		<form style={{ ...style }} onSubmit={handleSubmit(onSubmit)}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '1.0rem',
				}}
			>
				<FormInputText
					name={'phone'}
					control={control}
					label='Mobile Phone Number'
					// type='tel'
					variant='standard'
				/>

				<Button
					sx={{ maxWidth: '20%', marginTop: '0.8rem' }}
					type='submit'
					variant='contained'
				>
					Go
				</Button>
			</div>
			<Typography
				fontSize={'0.8rem'}
				color={'#aaaaaa'}
				marginTop={'0.2rem'}
			>
				Enter numbers only e.g. 4358675309
			</Typography>
		</form>
	)
}

export default SignInForm

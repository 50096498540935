import React from 'react'
import { Outlet, useNavigate } from 'react-router'
import TopNav from './TopNav'
import AppHeader from 'components/AppHeader'
import SettingsDrawer from 'components/SettingsDrawer'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import { useSubscriptionContext } from 'contexts/SubscriptionContext'
import { Button, Backdrop, Typography } from '@mui/material'
import Welcome from '../Welcome/Welcome'
import Loading from 'components/Loading'
import NoGroups from './NoGroups'

export type MainOutletContext = {
	setShowTopNav: (show: boolean) => void
	setSettingsDisabled: (disabled: boolean) => void
}

const MainLayout: React.FC = () => {
	const [showTopNav, setShowTopNav] = React.useState(true)
	const [settingsDisabled, setSettingsDisabled] = React.useState(false)
	const { profile, activeGroup } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	const {
		hasSubscription: activeSubscription,
		subscriberFullName,
		trialSubscription,
		subscriptionStatus,
	} = useSubscriptionContext()
	const navigate = useNavigate()
	const headerRef = React.useRef<HTMLDivElement>()

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: 10000 }}
				open={!activeSubscription}
			>
				<div
					style={{
						background: '#333333',
						margin: '0px 20px',
						borderRadius: '10px',
					}}
				>
					<div
						style={{
							display: userIsAdminOfGroup(
								profile?.active_group ?? ''
							)
								? 'block'
								: 'none',
						}}
					>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							Your subscription has expired. Please renew to
							continue using Joyner.
						</Typography>
						<div
							style={{
								textAlign: 'center',
								margin: '20px 0px 30px 0px',
							}}
						>
							<Button
								variant='contained'
								color='success'
								onClick={() => navigate('/account')}
							>
								Renew Subscription
							</Button>
						</div>
					</div>
					<div
						style={{
							display: userIsAdminOfGroup(
								profile?.active_group ?? ''
							)
								? 'none'
								: 'block',
						}}
					>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							The owner of this group&apos;s subscription has
							expired. Please contact {subscriberFullName} to
							renew their subscription.
						</Typography>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							Tap the button below to learn more about starting
							your own subscription.
						</Typography>
						<div
							style={{
								textAlign: 'center',
								margin: '20px 0px 30px 0px',
							}}
						>
							<Button
								variant='contained'
								color='success'
								onClick={() => navigate('/account')}
							>
								Subscription Information
							</Button>
						</div>
					</div>
				</div>
			</Backdrop>

			{profile ? (
				<>
					<>
						<AppHeader
							ref={headerRef}
							rightComponent={
								<SettingsDrawer
									disabled={settingsDisabled}
									anchor='right'
								/>
							}
							centerComponent={
								activeGroup && (
									<Button
										style={{
											color: '#ffffff',
											fontSize: '1.2rem',
											textTransform: 'none',
										}}
										onClick={() =>
											navigate(
												`/group/${activeGroup.id}`,
												{ state: { backPath: '/' } }
											)
										}
										data-intro='Tap the group name to view details about the group.'
									>
										{activeGroup.name}
									</Button>
								)
							}
						/>
						{profile.viewed_welcome ? (
							profile.active_group == null ? (
								<>
									<NoGroups />
								</>
							) : (
								<>
									{showTopNav && <TopNav />}

									<Outlet
										context={{
											setShowTopNav,
											setSettingsDisabled,
										}}
									/>
								</>
							)
						) : (
							<Welcome
								headerRef={headerRef}
								profile={profile}
								setShowTopNav={setShowTopNav}
								setSettingsDisabled={setSettingsDisabled}
							/>
						)}
					</>
				</>
			) : (
				<Loading />
			)}
		</>
	)
}

export default MainLayout

import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_EVENTS_TEXTBLOCKS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { EventTextBlock } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'
import { getQueryKey } from './get'

type UpdateEventTextBlockArgs = {
	textblockId: string
	textblock: Partial<Omit<EventTextBlock, 'id'>>
}
export const updateEventTextBlock = async ({
	textblockId,
	textblock,
}: UpdateEventTextBlockArgs) => {
	const { data, error } = await supabase
		.from(TABLE_EVENTS_TEXTBLOCKS)
		.update(textblock)
		.eq('id', textblockId)
		.select()
		.single()

	if (error) throw error

	return data
}

type UseUpdateEventTextBlockOptions = {
	options?: MutationConfig<typeof updateEventTextBlock>
}
export const useUpdateEventTextBlock = ({
	options,
}: UseUpdateEventTextBlockOptions = {}) => {
	return useMutation({
		onMutate: async (variables) => {
			const queryKey = getQueryKey({ textblockId: variables.textblockId })
			const previousEventTextBlock = queryClient.getQueryData(
				queryKey
			) as EventTextBlock
			queryClient.setQueryData(queryKey, variables.textblock)
			return { previousEventTextBlock }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError(error, variables, context: any) {
			if (context) {
				queryClient.setQueryData(
					getQueryKey({ textblockId: variables.textblockId }),
					context.previousEventTextBlock
				)
			}
		},
		onSuccess: (data) => {
			queryClient.setQueryData(
				getQueryKey({ textblockId: data.id }),
				data
			)
		},
		...options,
		mutationFn: updateEventTextBlock,
	})
}

import React, { useState } from 'react'
import { Box, Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { GroupVenue } from 'lib/supabase'
import { v4 as uuidV4 } from 'uuid'
import { DateTime } from 'luxon'
import { useDeleteVenue } from 'api/venue'
import { useNavigate } from 'react-router'
import VenueForm from 'components/forms/prebuilt/VenueForm'

type FormValues = {
	name: string
	phone?: string | null
	url?: string
	street?: string
	city?: string
	state?: string
	zip?: string
}

type Props = {
	readonly venue: GroupVenue
	onSave: (newVenue: GroupVenue) => void
	onCreateVenue: (newVenue: GroupVenue) => void
	onCancel: () => void
}
const EditVenue: React.FC<Props> = ({
	venue,
	onSave,
	onCreateVenue,
	onCancel,
}) => {
	const [showConfirm, setShowConfirm] = useState(false)
	const [creatingVenue, setCreatingVenue] = useState(venue.id === 'new')
	const deleteVenue = useDeleteVenue()
	const navigate = useNavigate()

	const handleSubmitForm = (data: FormValues) => {
		const newVenue: GroupVenue = {
			...venue,
			...data,
			id: creatingVenue ? uuidV4() : venue.id,
			created_at: DateTime.now().toISO(),
		}

		if (creatingVenue) {
			onCreateVenue(newVenue)
		} else {
			onSave(newVenue)
		}
	}

	const handleDeleteVenue = async () => {
		const groupId = venue.group_id
		await deleteVenue.mutateAsync({ venueId: venue.id })
		navigate(`/group/${groupId}/venues`, { state: { backPath: '/' } })
	}

	return (
		<Box>
			<AppHeader
				leftComponent={
					!creatingVenue && (
						<Button
							sx={{ color: '#0195f5' }}
							onClick={
								creatingVenue ? () => navigate(-1) : onCancel
							}
						>
							Cancel
						</Button>
					)
				}
				title={creatingVenue ? 'New Venue' : 'Edit Venue'}
			/>

			<VenueForm
				formId='edit-form'
				defaultValues={{
					name: venue.name,
					phone: venue.phone || '',
					url: venue.url || '',
					street: venue.street || '',
					city: venue.city || '',
					state: venue.state || '',
					zip: venue.zip || '',
				}}
				onCancel={() => {}}
				onSubmit={handleSubmitForm}
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '40px',
				}}
			>
				<div
					style={{
						display: creatingVenue ? 'flex' : 'none',
						flexDirection: 'row',
						gap: '10px',
						justifyContent: 'end',
						alignItems: 'right',
						margin: '20px 40px',
					}}
				>
					<Button onClick={() => navigate(-1)}>Cancel</Button>
					<Button variant='contained' type='submit' form='edit-form'>
						Save Changes
					</Button>
				</div>

				<div
					style={{
						display: creatingVenue ? 'none' : 'flex',
						flexDirection: 'column',
						gap: '10px',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '10px 40px',
					}}
				>
					<Button
						variant='contained'
						fullWidth
						style={{ maxWidth: '300px' }}
						type='submit'
						form='edit-form'
					>
						Save Changes
					</Button>
					<Button
						variant='outlined'
						color='error'
						fullWidth
						style={{
							maxWidth: '300px',
						}}
						onClick={() => setShowConfirm(true)}
					>
						Delete Venue
					</Button>
				</div>
			</AppBar>

			<ConfirmationDialog
				isOpen={showConfirm}
				onClose={() => setShowConfirm(false)}
				onConfirm={handleDeleteVenue}
				title={'Confirm Delete Venue'}
				content={'Do you want to permanently delete this venue?'}
				confirmButtonText='Delete Venue'
			/>
		</Box>
	)
}

export default EditVenue

import React, { useState, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CircleIcon from '@mui/icons-material/Circle'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import {
	IconButton,
	Paper,
	Tab,
	Typography,
	TextField,
	ButtonBase,
	SvgIcon,
	Fade,
	Slide,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DateTime } from 'luxon'
import { TransitionGroup } from 'react-transition-group'

const themePurple: themes = {
	background: '#7E57C2',
	button: '#5e35b1',
	border: '#9e9e9e',
	text: '#ffffff',
}

const themeIndigo: themes = {
	background: '#5c6bc0',
	button: '#3f51b5',
	border: '#9e9e9e',
	text: '#ffffff',
}

const themeGreen: themes = {
	background: '#43a047',
	button: '#2e7d32',
	border: '#9e9e9e',
	text: '#ffffff',
}

type Event = {
	id: string
	name: string
	start: string // ISO date string
	end: string // ISO date string
	location: string
	description: string
	theme: themes
}

const EventsData: Event[] = [
	{
		id: '1',
		name: 'Event 1',
		start: '2023-12-01T10:00:00Z',
		end: '2023-12-01T12:00:00Z',
		location: `Parley's Park Elementary School`,
		description: 'This is event 1',
		theme: themePurple,
	},
	{
		id: '2',
		name: 'Event 2',
		start: '2023-12-02T14:00:00Z',
		end: '2023-12-02T16:00:00Z',
		location: 'Ecker Hill Middle School',
		description: 'This is event 2',
		theme: themeIndigo,
	},
	{
		id: '3',
		name: 'Event 3',
		start: '2023-12-03T09:00:00Z',
		end: '2023-12-03T11:00:00Z',
		location: 'Park City High School',
		description: 'This is event 3',
		theme: themeGreen,
	},
]

type EventCardProps = {
	event: Event
	onClick: (id: string) => void
	onSwipe: (direction: 'Left' | 'Right' | 'Up' | 'Down') => void // Added onSwipe to handle swipe actions
}
const EventCard = forwardRef<HTMLDivElement, EventCardProps>(
	({ event, onClick, onSwipe, ...props }, ref) => {
		const handlers = useSwipeable({
			onSwiped: (eventData) => {
				console.log('User Swiped!', eventData)
				if (eventData.dir === 'Left' || eventData.dir === 'Right') {
					onSwipe(eventData.dir)
				}
			},
		})

		return (
			<div {...props} ref={ref}>
				<Paper
					{...handlers}
					elevation={3}
					style={{
						backgroundColor: event.theme.background,
						margin: '0px 20px',
						padding: '0px 20px',
						height: '70vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'center',
						position: 'relative',
						touchAction: 'pan-y',
						borderRadius: '20px',
						top: '0px',
					}}
				>
					<div>
						<Typography
							paddingTop={'20px'}
							fontSize={'1.4rem'}
							color={'#ffffff'}
							fontWeight={'500'}
						>
							{event.name}
						</Typography>
					</div>
					<div>
						<Typography color={'#ffffff'} fontSize={'0.9rem'}>
							{event.start &&
								`${DateTime.fromISO(event.start)
									.toFormat('cccc, LLLL d, y')
									.toLocaleString()}`}
						</Typography>
					</div>
					<div>
						<Typography color={'#ffffff'} fontSize={'0.9rem'}>
							{event.start &&
								`${DateTime.fromISO(event.start).toFormat(
									'h:mm a'.toLocaleString()
								)} - ${DateTime.fromISO(event.end)
									.toFormat('h:mm a ZZZZ')
									.toLocaleString()}`}
						</Typography>
					</div>
					<div style={{ marginTop: '10px', width: '100%' }}>
						<GlassButton
							fullWidth={true}
							text={event.location}
							theme={event.theme}
							onClick={() => {}}
						/>
					</div>
					<div style={{ marginTop: '10px', width: '100%' }}>
						<TextField
							fullWidth
							variant='outlined'
							multiline
							value={event.description}
							rows={4}
							sx={{
								backgroundColor: 'rgba(255, 255, 255, 0.3)',
								borderRadius: '5px',
							}}
						></TextField>
					</div>
					<div style={{ marginTop: '10px' }}>
						<GlassButton
							text='Hosted by Darren Ehlers'
							fullWidth={false}
							theme={event.theme}
							onClick={() => {}}
						/>
					</div>
					<div style={{ position: 'absolute', bottom: '20px' }}>
						<GlassButton
							text='More Info'
							fullWidth={false}
							theme={event.theme}
							onClick={() => {}}
						/>
					</div>
				</Paper>
			</div>
		)
	}
)

// DON'T KNOW WHY THIS IS NEEDED, BUT ESLINT IS COMPLAINING WITHOUT IT
EventCard.displayName = 'EventCard'

type themes = {
	background: string
	button: string
	border: string
	text: string
}

const GlassButton = ({
	text,
	fullWidth,
	theme,
	onClick,
}: {
	text: string
	fullWidth: boolean
	theme: themes
	onClick: () => void
}) => {
	return (
		<ButtonBase
			sx={{
				backgroundColor: theme.button,
				border: `1px solid ${theme.border}`,
				borderRadius: '50px',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
				color: theme.text,
				width: fullWidth ? '100%' : 'auto',
				padding: '10px 20px',
				textAlign: 'center',
			}}
			onClick={onClick}
		>
			{text}
		</ButtonBase>
	)
}

const Events = () => {
	const navigate = useNavigate()
	const [tabIndex, setTabIndex] = useState(0)

	const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
		console.log(tabIndex, newIndex)
		setTabIndex(newIndex)
	}

	const handleSwipe = (direction: 'Left' | 'Right') => {
		if (direction === 'Right' && tabIndex > 0) {
			setTabIndex(tabIndex - 1)
		} else if (direction === 'Left' && tabIndex < EventsData.length - 1) {
			setTabIndex(tabIndex + 1)
		}
	}

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate('/prototypes')}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'Events Prototype'}
			/>

			{/* EVENT CARDS */}
			<TabContext value={tabIndex}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<TabList
						centered
						onChange={handleChange}
						slotProps={{ indicator: { hidden: true } }}
						sx={{
							minHeight: '0px',
							padding: '10px 0px',
							position: 'absolute',
							bottom: '40px',
						}}
					>
						{EventsData.map((event, index) => (
							<Tab
								key={index}
								icon={
									tabIndex === index ? (
										<SvgIcon sx={{ fontSize: '1.0rem' }}>
											<CircleIcon />
										</SvgIcon>
									) : (
										<SvgIcon sx={{ fontSize: '1.0rem' }}>
											<PanoramaFishEyeIcon />
										</SvgIcon>
									)
								}
								disableRipple
								value={index}
								sx={{
									minWidth: '5px',
									minHeight: '0px',
									padding: '5px',
								}}
							/>
						))}
					</TabList>
				</div>
				{/* <TransitionGroup> */}
				{EventsData.map((event, index) =>
					tabIndex === index ? (
						<Slide
							key={index}
							direction='left'
							in={true}
							// timeout={{ enter: 500, exit: 500 }}
						>
							<div>
								<TabPanel
									value={index}
									sx={{
										marginTop: '40px',
										padding: '0px',
									}}
								>
									<EventCard
										event={event}
										onClick={() => {}}
										onSwipe={(direction) => {
											handleSwipe(
												direction as 'Left' | 'Right'
											)
										}}
									/>
								</TabPanel>
							</div>
						</Slide>
					) : null
				)}
				{/* </TransitionGroup> */}
			</TabContext>
		</>
	)
}

export default Events

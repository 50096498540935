import React from 'react'
import { Profile } from 'lib/supabase'
import { Avatar } from '@mui/material'
import { useGroupsContext } from 'contexts/GroupsContext'
import { stringAvatar } from 'utils'

type Props = {
	profile: Profile
	onClick: (event: React.MouseEvent<HTMLElement>) => void
	style?: React.CSSProperties
}

const UserAvatar: React.FC<Props> = ({ profile, onClick, style }) => {
	const { getGroupMemberAvatar } = useGroupsContext()

	return (
		<Avatar
			src={getGroupMemberAvatar(profile.id)}
			style={style}
			onClick={onClick}
			{...stringAvatar(profile?.full_name)}
			slotProps={{
				img: {
					loading: 'lazy',
				},
			}}
		/>
	)
}

export default React.memo(UserAvatar)

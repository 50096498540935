import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_ACTIVITIES_ATTENDANCE } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { JoinedActivity } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'
import { getQueryKey } from './get'

type UpdateAttendanceArgs = {
	activityId: string
	profileId: string
	status: 'in' | 'out'
}
export const updateAttendance = async ({
	activityId,
	profileId,
	status,
}: UpdateAttendanceArgs) => {
	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES_ATTENDANCE)
		.update({ attendance_status: status })
		.eq('activity_id', activityId)
		.eq('profile_id', profileId)
		.select()
		.single()

	if (error) throw error

	return data
}

type UseUpdateAttendanceOptions = {
	options?: MutationConfig<typeof updateAttendance>
}
export const useUpdateAttendance = ({
	options,
}: UseUpdateAttendanceOptions = {}) => {
	return useMutation({
		onMutate: async (variables) => {
			const queryKey = getQueryKey({
				activityId: variables.activityId,
			})
			const previousAttendance = queryClient.getQueryData(
				queryKey
			) as JoinedActivity
			queryClient.setQueryData(queryKey, variables.activityId)
			return { previousAttendance }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError(error, variables, context: any) {
			if (context) {
				queryClient.setQueryData(
					getQueryKey({
						activityId: variables.activityId,
					}),
					context.previousAttendance
				)
			}
		},
		onSuccess: (data) => {
			queryClient.setQueryData(
				getQueryKey({
					activityId: data.activity_id,
				}),
				data
			)
		},
		...options,
		mutationFn: updateAttendance,
	})
}

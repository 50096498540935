import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Divider,
} from '@mui/material'
import AppHeader from 'components/AppHeader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ReorderIcon from '@mui/icons-material/Reorder'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'

const Prototypes = () => {
	const navigate = useNavigate()

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate('/')}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={'Prototype Pages'}
			/>
			<div style={{ margin: '20px' }}>
				<List>
					<ListItemButton onClick={() => navigate('/aix')}>
						<ListItemAvatar>
							<Avatar>
								<AutoAwesomeIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary='AI Prototype' />
					</ListItemButton>
					<Divider />

					<ListItemButton onClick={() => navigate('/rte')}>
						<ListItemAvatar>
							<Avatar>
								<EditNoteIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary='TextBlock Prototype' />
					</ListItemButton>
					<Divider />

					<ListItemButton onClick={() => navigate('/dnd')}>
						<ListItemAvatar>
							<Avatar>
								<ReorderIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary='DND Prototype' />
					</ListItemButton>
					<Divider />

					<ListItemButton onClick={() => navigate('/events')}>
						<ListItemAvatar>
							<Avatar>
								<LocalActivityIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary='Events List Prototype' />
					</ListItemButton>
					<Divider />

					{/* <ListItemButton onClick={() => navigate('/nja')}>
						<ListItemAvatar>
							<Avatar>
								<JoinFullIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary='Joined Activities' />
					</ListItemButton>
					<Divider /> */}
				</List>
			</div>
		</>
	)
}

export default Prototypes

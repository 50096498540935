import { useQuery } from '@tanstack/react-query'
import queryClient from 'lib/reactQuery'
import supabase, { JoinedActivity } from 'lib/supabase'
import { TABLE_ACTIVITIES } from 'lib/supabase'
// import { getJoinedQueryKey } from './getJoined'
import { getQueryKey } from 'api/activity'
import { DateTime } from 'luxon'
import type { QueryConfig } from 'lib/reactQuery'

export const getActivitiesQueryKey = ({ groupId }: GetActivityArgs) => [
	'group',
	groupId,
	'joinedactivities',
]

type GetActivityArgs = {
	groupId: string
}

const getActivitiesFunction = async ({
	groupId,
}: GetActivityArgs): Promise<JoinedActivity[]> => {
	if (!groupId) return []

	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.select(`*,activities_attendance(*),activities_guests(*)`)
		.order('datetime', { ascending: true })
		.eq('group_id', groupId)
		.or('status.eq.active')

	if (error) {
		console.error(error)
		throw error
	}

	// before returning the activities, set status=expired for activities that are expired
	const expiredActivities =
		data.filter(
			(obj) =>
				DateTime.fromISO(obj.datetime) <= DateTime.now() &&
				obj.status === 'active'
		) || []

	if (expiredActivities.length > 0) {
		await Promise.all(
			expiredActivities.map((activity) =>
				supabase
					.from(TABLE_ACTIVITIES)
					.update({ status: 'expired' })
					.eq('id', activity.id)
			)
		)
	}
	return data as JoinedActivity[]
}

export const getActivities = async ({ groupId }: GetActivityArgs) =>
	// eslint-disable-next-line @tanstack/query/exhaustive-deps
	queryClient.fetchQuery({
		queryKey: getActivitiesQueryKey({ groupId }),
		queryFn: () => getActivitiesFunction({ groupId }),
	})

type UseGetActivityOptions = {
	groupId: string
	options?: QueryConfig<typeof getActivitiesFunction>
}

export const useGetActivities = ({
	groupId,
	options,
}: UseGetActivityOptions) => {
	// console.log('GetJoinedActivitiesByGroupId', groupId)

	return useQuery({
		...options,
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: getActivitiesQueryKey({ groupId }),
		queryFn: async () => {
			const activities = await getActivitiesFunction({
				groupId,
			})

			activities.forEach((activity) => {
				queryClient.setQueryData(
					getQueryKey({ activityId: activity.id }),
					activity
				)
			})

			return activities
		},
	})
}

import React, { useState, useEffect } from 'react'
import { getProfile } from '../../api/profile'
import type { Message, Profile, Reaction } from 'lib/supabase'
import { Box, Typography, Stack, Popover, IconButton } from '@mui/material'
import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import ActivityLinkBox from './ActivityLinkBox'
import CancelIcon from '@mui/icons-material/Cancel'
import { ActivityPopover, ContactPopover } from './Popovers'
// import { useGetReactionsByMessageId } from 'api/conversations/getReactionsByMessageId'
import useReactions from 'hooks/useReactions'
import MessagePopover from './MessagePopover'
import ReactionBubble from './ReactionBubble'
import UserAvatar from 'components/UserAvatar'
import { addReaction, deleteReaction } from 'api/messages'
import { v4 as UUIDv4 } from 'uuid'
import { DateTime } from 'luxon'

type Props = {
	message: Message
	userIsAuthor: boolean
	currentUserId: string
	userIsAdmin: boolean
	onDeleteMessage: (messageId: string) => void
}

const Bubble = React.forwardRef<Element | undefined, Props>(
	(
		{ message, userIsAuthor, currentUserId, userIsAdmin, onDeleteMessage },
		ref
	) => {
		const [profile, setProfile] = useState<Profile | null>(null)
		const [refresh, setRefresh] = useState(false)
		const [groupedReactions, setGroupedReactions] = useState<{
			[key: string]: number
		}>({})
		const [showReactions, setShowReactions] = useState(false)
		const [reactionProfiles, setReactionProfiles] = useState<{
			[key: string]: Profile | null
		}>({})

		const reactions = useReactions({
			messageId: message.id,
			chatId: message.chat_id || '',
			// options: { enabled: true },
		})

		// const reactions = useGetReactionsByMessageId({
		// 	messageId: message.id,
		// 	options: { enabled: true },
		// })

		useEffect(() => {
			if (reactions.data) {
				// for each reaction in reactionsResponse.data, group by reaction and get count
				// then setReactions to the grouped reactions
				const grouped = reactions.data.reduce(
					(acc: { [key: string]: number }, reaction: Reaction) => {
						if (!acc[reaction.reaction]) {
							acc[reaction.reaction] = 1
						} else {
							acc[reaction.reaction]++
						}
						return acc
					},
					{}
				)
				setGroupedReactions(grouped)
			}
		}, [reactions.data])

		useEffect(() => {
			const fetchProfile = async () => {
				const profileData = await getProfile({
					profileId: message.sender,
				})
				setProfile(profileData)
			}
			fetchProfile()
		}, [message.sender])

		useEffect(() => {
			const fetchReactionProfiles = async () => {
				if (reactions.data) {
					const profiles = await Promise.all(
						reactions.data.map(async (reaction) => {
							const profileData = await getProfile({
								profileId: reaction.sender_id,
							})
							return { [reaction.sender_id]: profileData }
						})
					)
					const profilesMap = profiles.reduce(
						(acc, profile) => ({ ...acc, ...profile }),
						{}
					)
					setReactionProfiles(profilesMap)
				}
			}
			fetchReactionProfiles()
		}, [reactions.data && showReactions === true])

		const [contactAnchorEl, setContactAnchorEl] =
			useState<null | HTMLElement>(null)
		const [activityAnchorEl, setActivityAnchorEl] =
			useState<null | HTMLElement>(null)
		const [messageAnchorEl, setMessageAnchorEl] =
			useState<null | HTMLElement>(null)

		const handleContactPopoverClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setContactAnchorEl(event.currentTarget)
		}

		const handleActivityLinkClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setActivityAnchorEl(event.currentTarget)
		}

		const handleMessagePopoverClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			setMessageAnchorEl(event.currentTarget)
		}

		const handleClose = () => {
			setContactAnchorEl(null)
			setActivityAnchorEl(null)
			setMessageAnchorEl(null)
		}

		const handleCopy = (contentToCopy: string | '') => {
			if (content) navigator.clipboard.writeText(contentToCopy)
			setContactAnchorEl(null)
			setActivityAnchorEl(null)
			setMessageAnchorEl(null)
		}

		const handleDelete = async (messageId: string) => {
			console.log('delete message with messageId ' + messageId)
			onDeleteMessage(messageId)
			setMessageAnchorEl(null)
		}

		const handleReaction = (reply: string) => {
			// check to see if the user has alreaady provided that reaction
			if (
				!reactions.data?.find((reaction) => reaction.reaction === reply)
			) {
				const newReaction = {
					id: UUIDv4(),
					created_at: DateTime.now().toISO(),
					message_id: message.id,
					reaction: reply.toString(),
					sender_id: currentUserId || '',
				}
				addReaction(newReaction).then(() => {
					setRefresh((prev) => !prev)
					reactions.refetch()
				})
				setRefresh((prev) => !prev)
				setMessageAnchorEl(null)
			} else {
				console.log('already reacted with ' + reply)
				setMessageAnchorEl(null)
			}
		}

		const handleDeleteReaction = async (messageId: string) => {
			deleteReaction(messageId).then(() => {
				setRefresh((prev) => !prev)
				reactions.refetch()
				setShowReactions(false)
			})
		}

		const handleShowReactions = () => {
			setShowReactions(true)
		}

		const messageBody = (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: userIsAuthor ? 'flex-end' : 'flex-start',
					paddingLeft: userIsAuthor ? '0' : '0.3rem',
					paddingRight: userIsAuthor ? '0.3rem' : '0',
					paddingY: '0.3rem',
				}}
			>
				<Box
					sx={{
						width: '90%',
						textAlign: userIsAuthor ? 'right' : 'left',
						backgroundColor: userIsAuthor ? '#90caf9' : '#ECEFF1',
						padding: '0.5rem 0.7rem',
						boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
						borderRadius: userIsAuthor
							? '10px 0px 10px 10px'
							: '0px 10px 10px 10px',
					}}
				>
					{userIsAuthor ? (
						// LOGGED IN USER BUBBLE
						<Stack direction={'column'} gap={1}>
							<Stack
								direction={'row'}
								gap={1}
								justifyContent={'space-between'}
								alignItems={'flex-start'}
								onClick={handleMessagePopoverClick}
							>
								<Typography
									textAlign={'left'}
									paddingY={'5px'}
									onClick={handleMessagePopoverClick}
								>
									{message.body}
								</Typography>
								<IconButton onClick={handleMessagePopoverClick}>
									<MoreVertIcon />
								</IconButton>
							</Stack>
							{message.activity_id != null ? (
								<div onClick={handleActivityLinkClick}>
									<ActivityLinkBox
										activityId={message.activity_id}
										authorBox={
											message.sender === currentUserId
										}
									/>
								</div>
							) : (
								<> </>
							)}
						</Stack>
					) : (
						// EVERYONE ELSE BUBBLE
						<Stack direction={'row'} gap={1}>
							{profile && (
								<UserAvatar
									profile={profile}
									onClick={handleContactPopoverClick}
									style={{}}
								/>
							)}
							<Stack direction={'column'} gap={1} width={'100%'}>
								<Stack
									direction={'row'}
									gap={1}
									justifyContent={'space-between'}
									alignItems={'flex-start'}
									onClick={handleMessagePopoverClick}
								>
									<Typography
										textAlign={'left'}
										paddingY={'5px'}
										onClick={handleMessagePopoverClick}
									>
										{message.body}
									</Typography>
									<IconButton
										onClick={handleMessagePopoverClick}
									>
										<MoreVertIcon />
									</IconButton>
								</Stack>

								{message.activity_id != null ? (
									<div onClick={handleActivityLinkClick}>
										<ActivityLinkBox
											activityId={message.activity_id}
											authorBox={
												message.sender === currentUserId
											}
										/>
									</div>
								) : (
									<> </>
								)}
							</Stack>
						</Stack>
					)}
				</Box>

				{groupedReactions && (
					<Stack
						direction={'row'}
						gap={0.2}
						justifyContent={'flex-start'}
						alignItems={'center'}
						paddingTop={'0.3rem'}
						paddingRight={userIsAuthor ? '1.5rem' : '0'}
						paddingLeft={userIsAuthor ? '0' : '2.0rem'}
					>
						<ReactionBubble
							reactions={groupedReactions}
							onClick={handleShowReactions}
						/>
					</Stack>
				)}

				<Popover
					id='contact-popover'
					anchorEl={contactAnchorEl}
					open={Boolean(contactAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{profile && (
						<ContactPopover
							contact={profile}
							onCopy={(contentToCopy) =>
								handleCopy(contentToCopy)
							}
							onCancel={handleClose}
						/>
					)}
				</Popover>

				<Popover
					id='activity-popover'
					anchorEl={activityAnchorEl}
					open={Boolean(activityAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{message.activity_id && (
						<ActivityPopover
							activityId={message.activity_id}
							onCopy={(contentToCopy) =>
								handleCopy(contentToCopy)
							}
							onCancel={handleClose}
						/>
					)}
				</Popover>

				<Popover
					id='message-popover'
					anchorEl={messageAnchorEl}
					open={Boolean(messageAnchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<MessagePopover
						message={message}
						profile={profile}
						userIsAuthor={userIsAuthor}
						userIsAdmin={userIsAdmin}
						onCopy={(contentToCopy) => handleCopy(contentToCopy)}
						onDelete={(messageId) => handleDelete(messageId)}
						onCancel={handleClose}
						onReaction={(reply) => handleReaction(reply)}
					/>
				</Popover>

				<Dialog
					fullWidth
					open={showReactions}
					onClose={() => setShowReactions(false)}
				>
					<DialogTitle
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography fontWeight={'500'} fontSize={'1.2rem'}>
							Reactions
						</Typography>
						<IconButton
							onClick={() => setShowReactions(false)}
							disableRipple
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<Divider />
							{reactions.data &&
								reactions.data.map((reaction) => (
									<>
										<div
											key={reaction.id}
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center',
												padding: '5px 0px 5px 0px',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'flex-start',
													alignItems: 'center',
												}}
											>
												<Typography width={'30px'}>
													{reaction.reaction}
												</Typography>
												<Typography>
													{
														reactionProfiles[
															reaction.sender_id
														]?.full_name
													}
												</Typography>
											</div>
											<div
												style={{
													display:
														currentUserId ===
														reaction.sender_id
															? 'flex'
															: 'none',
												}}
											>
												<IconButton
													color={'error'}
													onClick={() =>
														handleDeleteReaction(
															reaction.id
														)
													}
												>
													<CancelIcon />
												</IconButton>
											</div>
										</div>
										<Divider />
									</>
								))}
						</div>
					</DialogContent>
				</Dialog>
			</Box>
		)

		// for triggering the new message fetch
		const content = ref ? (
			<Box component={'article'} ref={ref}>
				{messageBody}
			</Box>
		) : (
			<Box component={'article'}>{messageBody}</Box>
		)

		return content
	}
)

// I have no idea why this is needed
// but eslint throws a fit if I don't add this
Bubble.displayName = 'ChatBubble'

export default Bubble

import supabase, { TABLE_GROUPS, TABLE_GROUPS_MEMBERSHIP } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'

import type { QueryConfig } from '../../lib/reactQuery'

export const getQueryKey = (groupId?: string) => ['group', groupId]

type GetGroupArgs = {
	groupId?: string
}
export const getGroupFunction = async ({ groupId }: GetGroupArgs) => {
	if (!groupId) throw new Error('groupId is required')

	const { data, error } = await supabase
		.from(TABLE_GROUPS)
		.select()
		.eq('id', groupId)
		.neq('status', 'deleted')
		.single()

	if (error) throw error

	return data
}

export const getGroup = async ({ groupId }: GetGroupArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey(groupId),
		queryFn: () => getGroupFunction({ groupId }),
	})

type UseGetGroupOptions = {
	groupId?: string
	options?: QueryConfig<typeof getGroupFunction>
}

export const useGetGroup = ({ groupId, options }: UseGetGroupOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey(groupId),
		queryFn: () => getGroupFunction({ groupId }),
	})
}

export const useGetGroupMembers = async (groupId?: string) => {
	const { data, error } = await supabase
		.from(TABLE_GROUPS_MEMBERSHIP)
		.select()
		.eq('group_id', groupId || '')

	if (error) throw error

	return data
}

import { useQuery } from '@tanstack/react-query'
import queryClient, { type QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_CHAT_CONVERSATIONS } from 'lib/supabase'

export const getConversationsQueryKey = (
	{ groupId }: GetConversationsArgs = { groupId: '' }
) => ['conversations', groupId]

type GetConversationsArgs = {
	groupId: string
}

const getConversationsFunction = async ({ groupId }: GetConversationsArgs) => {
	const { data, error } = await supabase
		.from(TABLE_CHAT_CONVERSATIONS)
		.select('*')
		.eq('group_id', groupId)

	if (error) throw error

	return data
}

export const getConversations = async ({ groupId }: GetConversationsArgs) =>
	queryClient.fetchQuery({
		queryKey: ['conversations', groupId],
		queryFn: () => getConversationsFunction({ groupId }),
	})

type UseGetConversationsOptions = {
	groupId: string
	options?: QueryConfig<typeof getConversationsFunction>
}

export const useGetConversations = ({
	groupId,
	options,
}: UseGetConversationsOptions) => {
	return useQuery({
		...options,
		queryKey: ['conversations', groupId],
		queryFn: () => getConversationsFunction({ groupId }),
	})
}

import supabase from '../../lib/supabase'

export const deleteReaction = async (reactionId: string) => {
	const { data, error } = await supabase
		.from('chat_reactions')
		.delete()
		.eq('id', reactionId)

	if (error) {
		throw new Error(error.message)
	}
	return data
}

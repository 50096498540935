import supabase from '../../lib/supabase'
import {
	TABLE_ACTIVITIES,
	TABLE_GROUPS,
	TABLE_PROFILES,
	TABLE_GROUPS_VENUES,
	TABLE_CHAT_MESSAGES,
	TABLE_POLLS,
	TABLE_POLLS_CHOICES,
} from 'lib/supabase'

import type {
	Activity,
	Group,
	Profile,
	GroupVenue,
	Message,
	Poll,
	PollChoice,
} from '../../lib/supabase'
import type { ToUpdate } from './types'

type UpdateGenericArgs =
	| {
			tableName: typeof TABLE_PROFILES
			data: ToUpdate<Profile>
	  }
	| {
			tableName: typeof TABLE_ACTIVITIES
			data: ToUpdate<Activity>
	  }
	| {
			tableName: typeof TABLE_GROUPS
			data: ToUpdate<Group>
	  }
	| {
			tableName: typeof TABLE_GROUPS_VENUES
			data: ToUpdate<GroupVenue>
	  }
	| {
			tableName: typeof TABLE_CHAT_MESSAGES
			data: ToUpdate<Message>
	  }
	| {
			tableName: typeof TABLE_POLLS
			data: ToUpdate<Poll>
	  }
	| {
			tableName: typeof TABLE_POLLS_CHOICES
			data: ToUpdate<PollChoice>
	  }
export const updateGeneric = async ({ tableName, data }: UpdateGenericArgs) => {
	if (data?.id == null) throw new Error('Function data is not defined')

	const { data: updatedData, error } = await supabase
		.from(tableName)
		.update(data)
		.eq('id', data.id)
		.select()
		.single<typeof data>()

	if (error) throw error

	return updatedData
}

import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'
import { TABLE_ACTIVITIES_GUESTS } from 'lib/supabase'
import { createGeneric } from '../generic'

import type { MutationConfig } from '../../lib/reactQuery'
import type { ActivityGuest } from '../../lib/supabase'

type CreateGuestArgs = {
	guest: ActivityGuest
}
export const createGuest = async ({ guest }: CreateGuestArgs) =>
	createGeneric({ tableName: TABLE_ACTIVITIES_GUESTS, data: guest })

type UseCreateGuestOptions = {
	options?: MutationConfig<typeof createGuest>
}
export const useCreateGuest = ({ options }: UseCreateGuestOptions = {}) => {
	return useMutation({
		onSuccess: (guest) => {
			if ('id' in guest) {
				queryClient.setQueryData(['guest', guest.id], guest)
				queryClient.invalidateQueries({ queryKey: ['guests'] })
			}
		},
		...options,
		mutationFn: createGuest,
	})
}

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Typography, Button } from '@mui/material'
import Loading from '../../components/Loading'
import SignInBox from './SignInBox'
import { useAuthContext } from 'contexts/AuthContext'
import AnonHeader from '../../components/AnonHeader'
import CookieConsentDialog from 'components/dialogs/CookieConsentDialog'

const Login: React.FC = () => {
	const { isAuthenticated, signOut } = useAuthContext()
	const navigate = useNavigate()
	const location = useLocation()
	const [loading, setLoading] = useState(true)

	// console.log(
	// 	'Login.tsx location.state.resumePath:',
	// 	location.state?.resumePath
	// )

	useEffect(() => {
		if (isAuthenticated) {
			if (location.state && location.state.resumePath) {
				const resumePath = location.state.resumePath
				navigate(resumePath, { replace: true })
			} else {
				navigate('/', { replace: true })
			}
			return
		} else if (isAuthenticated != null) {
			setLoading(false)
		}
	}, [isAuthenticated])

	const handleHelp = () => {
		// const hurl =
		// 	'https://airtable.com/embed/appV1wlnS9BGi44bT/pagwjekNetCHODW0V/form' +
		// 	'?prefill_SenderID=unauthenticated' +
		// 	'&hide_SenderID=true'
		const hurl = 'https://help.joyner.app'

		window.open(hurl, '_blank')
	}

	const handleCreateAccount = () => {
		navigate('/create')
	}

	return (
		<>
			<main>
				{loading ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100vh',
						}}
					>
						<Loading message={'Loading...'} />
						<Button variant='contained' onClick={signOut}>
							Sign Out
						</Button>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						{/* HERO IMAGE */}
						<AnonHeader />

						<div
							style={{
								width: '100%',
								maxWidth: '600px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{/* HEADER */}
							<Typography
								component='h1'
								sx={{
									textAlign: 'center',
									fontWeight: '500',
									fontSize: '1.6em',
									marginTop: '2rem',
									marginBottom: '2rem',
								}}
							>
								Sign In to Joyner
							</Typography>

							<SignInBox />

							<div
								style={{
									marginTop: '20px',
									marginBottom: '10px',
									width: '80%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'end',
								}}
							>
								<Typography
									fontWeight={'500'}
									paddingTop={'0.35rem'}
								>
									New user?
								</Typography>
								<Button
									variant='text'
									onClick={handleCreateAccount}
								>
									Create an account
								</Button>
							</div>

							{/* HELP LINK */}
							<div
								style={{
									marginBottom: '10px',
									width: '80%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'end',
								}}
							>
								<Typography
									fontWeight={'500'}
									paddingTop={'0.35rem'}
								>
									Having trouble?
								</Typography>
								<Button onClick={handleHelp} variant='text'>
									Get help signing in
								</Button>
							</div>
						</div>
					</div>
				)}
			</main>

			<CookieConsentDialog />
		</>
	)
}

export default Login

import React from 'react'
import { Typography, Button, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DateTime } from 'luxon'
import { JoinedActivity } from 'lib/supabase'
import { useNavigate } from 'react-router'
import { useGetVenue } from 'api/venue/get'
import AttendingBlock from './ActivityAttendingBox'

type Props = {
	activity: JoinedActivity
	profileId: string
	onAttendance: (
		profileId: string,
		activityId: string,
		status: 'in' | 'out' | '',
		action: string
	) => void
}

const ActivityInfoBox: React.FC<Props> = ({
	activity,
	profileId,
	onAttendance,
}) => {
	const navigate = useNavigate()
	const venue = useGetVenue({ venueId: activity.venue_id })

	const handleAttendance = (
		profileId: string,
		activityId: string,
		status: 'in' | 'out' | '',
		action: string
	) => {
		onAttendance(profileId, activity.id, status, action)
	}

	const handleNavigateToActivity = () => {
		navigate(`/activity/${activity.id}`, {
			state: {
				backPath: '/',
			},
		})
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				textAlign: 'center',
				marginTop: '5px',
			}}
		>
			{/* THIS SECTION IS REPEATED IF MORE THAN ONE ACTIVITY FOR SAME DAY */}
			<div
				style={{
					borderRadius: '5px',
					background: '#f7f7f7',
					overflow: 'hidden',
					width: '100%',
				}}
			>
				<div
					style={{
						textAlign: 'left',
						margin: '0.0rem 0.0rem 0.0rem 0.6rem',
						overflow: 'hidden',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Button
							style={{
								textTransform: 'none',
								fontSize: '1.1rem',
								fontWeight: 'bold',
								paddingLeft: '0px',
								justifyContent: 'flex-start',
							}}
							fullWidth
							disableRipple
							variant='text'
							onClick={handleNavigateToActivity}
							// data-intro={
							// 	props.introSteps
							// 		? 'Tap the activity title to view more details about the activity.'
							// 		: undefined
							// }
						>
							{activity.name}
						</Button>
						<IconButton
							className='moreInfoIcon'
							disableRipple
							style={{
								paddingTop: '0.8rem',
								color: '#1565C0',
							}}
							onClick={handleNavigateToActivity}
						>
							<MoreVertIcon />
						</IconButton>
					</div>

					{/* TIME & VENUE */}
					<Typography fontSize={'0.9rem'}>
						{DateTime.fromISO(activity.datetime)
							.toFormat('h:mm a ZZZZ')
							.toLocaleString()}
						&nbsp;&ndash;&nbsp;
						{venue.isSuccess && venue.data.name}
					</Typography>

					{/* EXTRA NOTE */}
					<Typography
						color={'#333333'}
						fontStyle={'italic'}
						fontSize={'0.8rem'}
						style={{
							marginTop: '0.2rem',
							marginRight: '0.6rem',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{activity.note}
					</Typography>

					<div
						style={{
							width: '100%',
							overflow: 'hidden',
						}}
					>
						<div style={{ paddingTop: '10px' }}>
							<AttendingBlock
								activity={activity}
								profileId={profileId}
								onAttendance={(
									profileId: string,
									activityId: string,
									status: 'in' | 'out' | '',
									action: string
								) =>
									handleAttendance(
										profileId,
										activityId,
										status,
										action
									)
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ActivityInfoBox

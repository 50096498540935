import {
	ToggleButtonGroup,
	ToggleButton,
	AppBar,
	Paper,
	Fab,
	Badge,
	Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React, { useState } from 'react'
import useRouteMatch from 'hooks/useRouteMatch'
import { useNavigate } from 'react-router'
import { useUserContext } from '../../contexts/UserContext'
import { useAlertsContext } from '../../contexts/AlertsContext'

const TopNav = () => {
	const navigate = useNavigate()
	const { profile } = useUserContext()
	const routeMatch = useRouteMatch(['/', '/polls', '/conversations'])
	const [currentTab, setCurrentTab] = useState(routeMatch?.pattern?.path)
	const { showMessagesBadge, onReset } = useAlertsContext()

	const handleTopNavClick = (
		event: React.MouseEvent<HTMLElement>,
		newTab: string
	) => {
		if (newTab === null) return
		setCurrentTab(newTab)
		if (newTab === '/conversations') {
			onReset()
		}
		navigate(newTab)
	}

	const handleBottomClick = () => {
		if (currentTab === '/') {
			navigate('/create/activity', { state: { backPath: '/' } })
		} else if (currentTab === '/conversations') {
			navigate('/create/conversation', { state: { backPath: '/' } })
		} else if (currentTab === '/polls') {
			navigate('/create/poll', { state: { backPath: '/' } })
		}
	}

	return (
		<>
			<div
				id='topNav'
				color='primary'
				style={{
					display: 'flex',
					backgroundColor: '#333333',
					position: 'sticky',
					top: '100px',
					width: '100%',
					padding: '0px 0px 10px 0px',
					zIndex: 1000,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						width: '95%',
						marginTop: '2px',
						marginBottom: '2px',
					}}
				>
					<ToggleButtonGroup
						value={currentTab}
						onChange={handleTopNavClick}
						exclusive
						fullWidth
						size='medium'
						data-intro='Tap different tabs to view more info for your group.'
					>
						<ToggleButton
							href=''
							value='/'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								backgroundColor:
									currentTab === '/' ? '#ffffff' : '#212121',
							}}
						>
							<Typography
								fontSize={'0.9rem'}
								fontWeight={
									currentTab === '/' ? 'bold' : 'normal'
								}
								color={
									currentTab === '/' ? '#000000' : '#ffffff'
								}
							>
								Home
							</Typography>
						</ToggleButton>
						<ToggleButton
							href=''
							id='conversations'
							value='/conversations'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								backgroundColor:
									currentTab === '/conversations'
										? '#ffffff'
										: '#212121',
							}}
						>
							<Badge
								invisible={!showMessagesBadge}
								variant='dot'
								color='error'
							>
								<Typography
									fontSize={'0.9rem'}
									fontWeight={
										currentTab === '/conversations'
											? 'bold'
											: 'normal'
									}
									color={
										currentTab === '/conversations'
											? '#000000'
											: '#ffffff'
									}
								>
									Messages
								</Typography>
							</Badge>
						</ToggleButton>
						<ToggleButton
							href=''
							value='/polls'
							style={{
								lineHeight: '1.0rem',
								padding: '8px 11px',
								backgroundColor:
									currentTab === '/polls'
										? '#ffffff'
										: '#212121',
							}}
						>
							<Typography
								fontSize={'0.9rem'}
								fontWeight={
									currentTab === '/polls' ? 'bold' : 'normal'
								}
								color={
									currentTab === '/polls'
										? '#000000'
										: '#ffffff'
								}
							>
								Polls
							</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>
			<AppBar position='fixed' sx={{ top: 'auto', bottom: 0 }}>
				<Paper
					elevation={3}
					style={{
						height: '50px',
						paddingTop: '10px',
						paddingLeft: '20%',
						paddingRight: '20%',
						paddingBottom: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Fab
						color='success'
						aria-label='add'
						style={{ position: 'relative', top: '-24px' }}
						onClick={handleBottomClick}
						data-intro='Tap the Create New button to create new items for your group.'
					>
						<AddIcon />
					</Fab>
				</Paper>
			</AppBar>
		</>
	)
}

export default TopNav

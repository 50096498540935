import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router'
import {
	getQueryKey,
	useDeleteActivity,
	useGetActivity,
	useUpdateActivity,
} from 'api/activity'
import {
	useCreateVenue,
	useGetManyByGroupId as getGroupVenues,
} from 'api/venue'
import { useCreateGuest, useDeleteGuest } from 'api/guest'
import { useGetManyByGroupId as useGetMembersOfGroup } from 'api/profile'
import { useGetProfiles } from 'api/profile'
import Loading from 'components/Loading'
import ViewActivity from './ViewActivity'
import type {
	Activity,
	ActivityAttendance,
	GroupVenue,
	ActivityGuest,
	Message,
	Conversation,
} from 'lib/supabase'
import EditActivity from './EditActivity'
import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'
import ErrorComponent from 'components/ErrorComponent'
import { Typography } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import { distributeNotifications } from 'utils'
import QuickAddGuestDialog from 'components/dialogs/QuickAddGuestDialog'
import GroupMembersDialog from 'components/dialogs/GroupMembersDialog'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'
import { addMessage } from 'api/messages'
import { createConversation, useGetConversations } from 'api/conversations'
import { useDeleteAttendance } from 'api/activity/deleteAttendance'
import { useCreateAttendance } from 'api/activity/createAttendance'
import { useQueryClient } from '@tanstack/react-query'

type InOut = {
	id: string
	full_name: string
	guest: boolean
}

const ActivityPage: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const location = useLocation()
	const [editing, setEditing] = useState(false)
	const [deletePending, setDeletePending] = useState(false)
	const updateActivity = useUpdateActivity()
	const deleteActivity = useDeleteActivity()
	const createVenue = useCreateVenue()
	const createGuest = useCreateGuest()
	const [showGuestQuickAdd, setShowGuestQuickAdd] = useState(false)
	const [showGroupMembers, setShowGroupMembers] = useState(false)
	const [showSendMessage, setShowSendMessage] = useState(false)
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
		useState(false)
	const deleteAttendance = useDeleteAttendance()
	const createAttendance = useCreateAttendance()
	const deleteGuest = useDeleteGuest()
	const queryClient = useQueryClient()

	// LOAD ACTIVITY FROM URL PARAM INCLUDING ATTENDANCE & GUESTS FROM JOINED TABLES
	const { activityId } = useParams()
	const activity = useGetActivity({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	const venues = getGroupVenues({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	if (activity.isError || venues.isError) {
		console.error(activity.error)
		throw new Error('Error fetching activity or venues')
	}

	const { data: conversations } = useGetConversations({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})

	const { data: members } = useGetMembersOfGroup({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})

	const inProfileIds =
		activity?.data?.activities_attendance
			.filter((attendance) => attendance.attendance_status === 'in')
			.map((attendance) => attendance.profile_id) || []

	const { data: inProfiles } = useGetProfiles({
		profileIds: inProfileIds,
		options: { enabled: activity.isSuccess },
	})

	const ins: InOut[] =
		inProfiles?.map((profile) => ({
			id: profile.id,
			full_name: profile.full_name,
			guest: false,
		})) || []

	let gins: InOut[] = []
	if (activity.isSuccess && activity.data.activities_guests) {
		gins = activity.data.activities_guests.map(
			(guest) =>
				({
					id: guest.id,
					full_name: guest.full_name + ' (GUEST)',
					guest: true,
				} as InOut)
		)
	}
	const combinedIns = [...(ins || []), ...(gins || [])] as InOut[]

	const outProfileIds =
		activity?.data?.activities_attendance
			.filter((attendance) => attendance.attendance_status === 'out')
			.map((attendance) => attendance.profile_id) || []

	const { data: outProfiles } = useGetProfiles({
		profileIds: outProfileIds,
		options: { enabled: activity.isSuccess },
	})

	const outs: InOut[] =
		outProfiles?.map((profile) => ({
			id: profile.id,
			full_name: profile.full_name,
			guest: false,
		})) || []

	const venue = useMemo(() => {
		return venues.data?.find((v) => v.id === activity.data?.venue_id)
	}, [venues.data, activity.data?.venue_id])

	useEffect(() => {
		if (activity.isSuccess) {
			const chan = supabase
				.channel('db-changes')
				.on(
					'postgres_changes',
					{
						event: '*',
						schema: 'public',
						table: TABLE_ACTIVITIES,
					},
					async (payload) => {
						console.log(
							'ActivityPage::useEffect::payload',
							payload.eventType
						)
						if (payload.eventType === 'INSERT') {
							const newActivity = payload.new as Activity
							if (
								newActivity &&
								newActivity.id === activity.data.id
							) {
								await queryClient.setQueryData(
									getQueryKey({ activityId: newActivity.id }),
									payload.new
								)
							}
						} else if (payload.eventType === 'UPDATE') {
							queryClient.invalidateQueries({
								queryKey: getQueryKey({
									activityId: (payload.new as Activity).id,
								}),
							})
						} else if (payload.eventType === 'DELETE') {
							queryClient.removeQueries({
								queryKey: getQueryKey({
									activityId: (payload.old as Activity).id,
								}),
							})
							navigate('/')
						}
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	}, [activity])

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = (dialogAction: string, actionTaken: boolean) => {
		console.log('ActivityPage::handleCancel: ', dialogAction, actionTaken)
		if (editing && dialogAction === 'cancel-header') {
			setEditing(false)
		} else if (dialogAction === 'delete' || dialogAction === 'new') {
			if (deletePending && activityId)
				deleteActivity.mutate({ activityId: activityId })
			navigate('/')
		} else if (dialogAction === 'update' && actionTaken) {
			// activity changes have already been saved
			setEditing(false)
		}
	}

	const handleSave = async (newActivity: Partial<Omit<Activity, 'id'>>) => {
		if (!activity.isSuccess) return

		await updateActivity
			.mutateAsync({
				activityId: activity.data.id,
				activity: newActivity,
			})
			.then(() => {
				queryClient.invalidateQueries({
					queryKey: getQueryKey({
						activityId: activity.data.id,
					}),
				})
			})
	}

	const handleRemoveIn = async (uid: string) => {
		const member = combinedIns.find((i) => i.id === uid)
		if (member?.guest && activity.data) {
			deleteGuest.mutateAsync({ profileId: uid }).then(() => {
				queryClient.invalidateQueries({
					queryKey: getQueryKey({
						activityId: activity.data.id,
					}),
				})
			})
		} else if (!member?.guest && activity.data) {
			deleteAttendance
				.mutateAsync({ profileId: uid, activityId: activity.data.id })
				.then(() => {
					queryClient.invalidateQueries({
						queryKey: getQueryKey({
							activityId: activity.data.id,
						}),
					})
				})
		}
	}

	const showAddGuest = () => {
		setShowGuestQuickAdd(true)
	}

	const handleAddGuest = async (newGuest: ActivityGuest) => {
		if (activityId) newGuest.activity_id = activityId
		if (profile?.id) newGuest.created_by = profile?.id
		newGuest.created_at = DateTime.now().toISO()

		createGuest.mutateAsync({ guest: newGuest }).then(() => {
			if (activity.data) {
				queryClient.invalidateQueries({
					queryKey: getQueryKey({ activityId: activity.data.id }),
				})
			}
		})
		setShowGuestQuickAdd(false)
	}

	const showAddMember = () => {
		setShowGroupMembers(true)
	}

	const showSendMessageDialog = () => {
		setShowSendMessage(true)
	}

	const handleAddMember = async (selected: string[]) => {
		if (!activity.data) return
		selected.map((member) => {
			const index = inProfileIds.findIndex((i) => i === member)
			if (index === -1) {
				const newAttendance = {
					profile_id: member,
					activity_id: activity.data.id,
					attendance_status: 'in',
				} as ActivityAttendance
				createAttendance
					.mutateAsync({ attendance: newAttendance })
					.then(() => {
						queryClient.invalidateQueries({
							queryKey: getQueryKey({
								activityId: activity.data.id,
							}),
						})
					})
			}
		})

		setShowGroupMembers(false)
	}

	const handleDeleteActivity = () => {
		if (activityId && activity.isSuccess) {
			if (activity.data.status === 'active') {
				setDeletePending(true)
			} else {
				// deleting expired activity, no need for notifications
				deleteActivity.mutate({ activityId: activityId })
				navigate('/')
			}
		}
	}

	const handleCreateVenue = async (
		venue: GroupVenue
	): Promise<Error | void> => {
		try {
			await createVenue.mutateAsync({ venue })
		} catch (err) {
			return err as Error
		}
	}

	const handleSendNotifications = async (
		selected: string[],
		message: string,
		type: string
	) => {
		if (!activity.data || !profile || !activeGroup) return

		console.log(
			'ActivityPage::handleSendNotifications - send notifications to selected members for action type ' +
				type
		)
		distributeNotifications(
			'activity',
			type,
			selected,
			message,
			profile.full_name,
			activeGroup?.id,
			activity.data.id
		)

		// add message to either private or group conversations
		let newMessage: Message = {
			id: v4().toString(),
			activity_id: activity.data.id,
			chat_id: '',
			body: message,
			created_at: DateTime.now().toISO(),
			sender: profile.id,
			group_id: activeGroup.id,
		}
		const filtered = members?.length != selected.length
		if (filtered) {
			const chatExists = conversations?.find(
				(conversation) =>
					conversation.participants?.length === selected.length &&
					conversation.participants?.every((participant) =>
						selected.includes(participant)
					)
			) as Conversation | null

			if (chatExists) {
				newMessage = {
					...newMessage,
					chat_id: chatExists.id,
				}
				addMessage(newMessage)
			} else {
				const newConversationId = v4()
				const newConversation: Conversation = {
					id: newConversationId,
					created_at: DateTime.now().toISO(),
					updated_at: null,
					created_by: profile.id,
					group_id: activeGroup.id,
					participants: selected,
					status: 'active',
					last_sender_id: profile.id,
				}
				createConversation({ conversation: newConversation }).then(
					() => {
						newMessage = {
							...newMessage,
							chat_id: newConversationId,
						}
						addMessage(newMessage)
					}
				)
			}
		} else {
			addMessage(newMessage)
		}

		showSnackbar('Notifications sent to selected members', 'success')
		if (type === 'message') setShowSendMessage(false)
		if (type === 'update') setEditing(false)
		if (type === 'delete') {
			if (deletePending && activityId)
				deleteActivity.mutate({ activityId: activityId })
			navigate('/')
		}
	}

	const handleBack = () => {
		if (location.state && location.state.backPath) {
			navigate(location.state.backPath)
		} else {
			navigate('/')
		}
	}

	return (
		<main>
			{activity.isSuccess && venues.isSuccess && venue && profile ? (
				editing ? (
					<EditActivity
						venues={venues.data}
						activity={activity.data}
						onSave={handleSave}
						onCancel={(
							dialogAction: string,
							actionTaken: boolean
						) => handleCancel(dialogAction, actionTaken)}
						onDeleteActivity={handleDeleteActivity}
						onCreateVenue={handleCreateVenue}
						onSendNotifications={handleSendNotifications}
					/>
				) : (
					<ViewActivity
						activity={activity.data}
						canViewButtons={
							activity.data.activity_owner_id === profile.id ||
							userIsAdminOfGroup(activity.data.group_id)
						}
						ins={combinedIns}
						outs={outs}
						venue={venue}
						onBack={handleBack}
						onEdit={handleEdit}
						onDelete={handleDeleteActivity}
						onRemoveIn={handleRemoveIn}
						onShowAddGuest={showAddGuest}
						onShowAddMember={showAddMember}
						onShowSendMessage={showSendMessageDialog}
					/>
				)
			) : deletePending ? (
				<Typography>Activity is Pending Delete</Typography>
			) : activity.isError || venues.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}

			<QuickAddGuestDialog
				isOpen={showGuestQuickAdd}
				onCancel={() => setShowGuestQuickAdd(false)}
				onSubmit={(newGuest: ActivityGuest) => handleAddGuest(newGuest)}
			/>

			<GroupMembersDialog
				isOpen={showGroupMembers}
				onCancel={() => setShowGroupMembers(false)}
				onList={(selected: string[]) => handleAddMember(selected)}
				activeGroup={activeGroup?.id}
				ins={inProfileIds}
				outs={outProfileIds}
				maxSpots={activity.data?.max_spots ?? 0}
				guests={activity.data?.activities_guests.length}
				title='Add Member(s) to Activity'
				buttonText='Add Selected'
				selectAll={false}
				filterList={true}
				currentUserId={profile?.id}
				forceCheckCurrentUser={false}
				currentList={[]}
			/>

			<SendNotificationDialog
				isOpen={showSendMessage}
				onCancel={() => setShowSendMessage(false)}
				activeGroup={activeGroup?.id}
				profileId={profile?.id}
				selectAll={true}
				forceSendAll={false}
				title='Send Message'
				textFieldLabel='Message to Send'
				showConfirm={false}
				showConfirmText=''
				confirmOnly={false}
				dialogType='notify'
				objectType='activity'
				dialogAction='message'
				onUpdateDeleteAction={() => {}}
				onSendNotifications={(selected: string[], message: string) => {
					handleSendNotifications(selected, message, 'message')
				}}
			/>

			<ConfirmationDialog
				isOpen={showConfirmDeleteDialog}
				title='Delete Activity'
				confirmButtonText='Delete'
				content='Are you sure you want to delete this activity?'
				onConfirm={handleDeleteActivity}
				onClose={() => setShowConfirmDeleteDialog(false)}
			/>
		</main>
	)
}

export default ActivityPage

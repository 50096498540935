import React, { useContext, useEffect, useState } from 'react'
import supabase, { Group, Profile, TABLE_PROFILES } from '../lib/supabase'
import { type User } from '@supabase/supabase-js'
import {
	useCreateProfile,
	getMaybe,
	useUpdateProfile,
	getQueryKey,
} from 'api/profile'
import { getManyQueryKey, useGetGroup } from 'api/group'
import { useAuthContext } from './AuthContext'
import queryClient from 'lib/reactQuery'
import { DEFAULT_ERROR_MESSAGE, GROUP_TIMESTAMP_COOKIE_KEY } from 'utils'
import { useSnackbarContext } from './SnackbarContext'
import { useLocalStorage } from 'usehooks-ts'
import { DateTime } from 'luxon'

type UserContextType = {
	user?: User
	profile?: Profile
	activeGroup?: Group
	prevLastAccess: string | null
	isAdminOfActiveGroup?: boolean
	updateProfile: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>
	setActiveGroup: (groupId: string | null) => Promise<void>
	getAvatarUrl: (avatar: string) => Promise<string>
	uploadScratchFile: (file: File) => Promise<string>
	deleteScratchFiles: (path: string[]) => Promise<void>
	getPublicUrl: (path: string) => Promise<string>
	uploadAvatar: (file: File, draft: boolean) => Promise<string>
	publishAvatar: (path: string) => Promise<string>
	deleteAvatar: (path: string) => Promise<void>
}
const UserContext = React.createContext<UserContextType | undefined>(undefined)

export const useUserContext = () => {
	const context = useContext(UserContext)
	if (!context) throw new Error('No user context provided')
	return context
}

export const UserContextProvider: React.FC<React.PropsWithChildren> = (
	props
) => {
	const { session, getNewUserInfo } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const [prevLastAccess, setPrevLastAccess] = useState<string | null>(null)
	const [user, setUser] = useState<User>()
	const [profile, setProfile] = useState<Profile>()
	const activeGroup = useGetGroup({
		groupId: profile?.active_group || undefined,
		options: { enabled: !!profile && profile?.active_group != null },
	})
	const updateProfile = useUpdateProfile()
	const createProfile = useCreateProfile()
	const [, setActiveGroupTimestamps] = useLocalStorage<{
		[groupId: string]: string
	}>(GROUP_TIMESTAMP_COOKIE_KEY, {})

	useEffect(() => {
		if (session) {
			setUser(session.user)
			getMaybe({ profileId: session.user.id }).then(async (profile) => {
				if (profile) {
					const lastAccess = profile.last_access_at
					setPrevLastAccess(lastAccess)
					setProfile(profile)
					profile.last_access_at = DateTime.now().toISO()
					await updateProfile.mutateAsync({
						profile: { ...profile },
						profileId: profile.id,
					})
				} else {
					const newUserInfo = getNewUserInfo()

					const data = await createProfile.mutateAsync({
						profile: {
							id: session.user.id,
							email: newUserInfo?.email || session.user.email!,
							active_group: newUserInfo?.groupId || null,
							phone_number: session.user.phone!,
							full_name:
								session.user.user_metadata?.full_name || null,
							stripe_customer_id: null,
							allow_sms: !!session.user.phone,
							allow_email: true,
							created_at: DateTime.now().toISO(),
							updated_at: null,
							viewed_welcome: null,
							last_access_at: DateTime.now().toISO(),
						},
					})

					setProfile(data as Profile)
				}
			})
		}
	}, [session])

	const handleUpdateProfile = async (
		newProfileData: Partial<Profile>
	): Promise<void> => {
		if (!profile) return
		const prevProfile = { ...profile }
		try {
			// optimistic update
			// due to some closure shenanigans, we need to reference the previous value here
			setProfile((prev): Profile | undefined => {
				if (!prev) return undefined

				return {
					...prev,
					...newProfileData,
				}
			})

			await updateProfile.mutateAsync({
				profileId: profile.id,
				profile: { ...newProfileData },
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			// revert optimistic update
			setProfile(prevProfile)
			setPrevLastAccess(prevProfile.last_access_at)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	// subscribe to realtime changes for current profile
	useEffect(() => {
		if (profile) {
			const chan = supabase
				.channel('db-changes')
				.on(
					'postgres_changes',
					{
						event: 'UPDATE',
						schema: 'public',
						table: TABLE_PROFILES,
						filter: `id=eq.${profile.id}`,
					},
					(payload) => {
						// update the current profile when it changes
						const newProfile = payload.new as Profile
						setProfile(newProfile)
						queryClient.setQueryData(
							getQueryKey(profile.id),
							newProfile
						)
						queryClient.invalidateQueries({
							queryKey: getManyQueryKey(profile.id),
						})
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	}, [profile])

	const value: UserContextType = {
		user,
		profile,
		prevLastAccess: prevLastAccess,
		activeGroup: activeGroup.data,
		isAdminOfActiveGroup: activeGroup.data?.admin === user?.id,
		updateProfile: async (newProfileData) => {
			await handleUpdateProfile(newProfileData)
		},
		setActiveGroup: async (groupId) => {
			await handleUpdateProfile({ active_group: groupId })

			if (groupId != null) {
				setActiveGroupTimestamps((prevTimestamps) => ({
					...prevTimestamps,
					[groupId]: new Date().toISOString(),
				}))
			}
		},
		getAvatarUrl: async (avatar: string) => {
			const { data, error } = await supabase.storage
				.from('avatar-images')
				.createSignedUrl(avatar, 3600) // expires in 1 hour
			if (error) {
				if (error.message.includes('Object not found')) {
					return ''
				} else {
					throw new Error(
						'Failed to get signed URL - ' + error.message
					)
				}
			}
			return data.signedUrl
		},
		uploadScratchFile: async (file: File) => {
			const { data, error } = await supabase.storage
				.from('scratch')
				.upload(file.name, file, {
					cacheControl: '3600',
					upsert: true,
				})
			if (error) {
				throw new Error('Failed to upload file - ' + error.message)
			}
			return data.path
		},
		deleteScratchFiles: async (path: string[]) => {
			const { error } = await supabase.storage
				.from('scratch')
				.remove(path)
			if (error) {
				throw new Error('Failed to delete file - ' + error.message)
			}
		},
		getPublicUrl: async (path: string) => {
			const { data } = await supabase.storage
				.from('scratch')
				.getPublicUrl(path)
			if (!data) {
				throw new Error('Failed to get public URL')
			}
			return data.publicUrl
		},
		uploadAvatar: async (file: File, draft: boolean) => {
			const fileName = profile?.id + '.' + file.name.split('.')[1]
			const bucket = draft ? 'scratch' : 'avatar-images'
			console.log('Uploading avatar ' + fileName + ' to bucket ' + bucket)
			const { data, error } = await supabase.storage
				.from(bucket)
				.upload(fileName, file, {
					cacheControl: '3600',
					upsert: true,
				})
			if (error) {
				console.log('Error uploading avatar:', error.message)
				throw new Error('Failed to upload file - ' + error.message)
			}
			return data.path
		},
		publishAvatar: async (path: string) => {
			// have to delete existing file as no option to allow overwrite
			await supabase.storage.from('avatar-images').remove([path])

			const { data, error } = await supabase.storage
				.from('scratch')
				.copy(path, path, {
					destinationBucket: 'avatar-images',
				})
			if (error) {
				console.log('Error uploading avatar:', error.message)
				throw new Error('Failed to upload file - ' + error.message)
			}
			return data?.path
		},
		deleteAvatar: async (path: string) => {
			await supabase.storage.from('avatar-images').remove([path])
		},
	}

	return (
		<UserContext.Provider value={value}>
			{props.children}
		</UserContext.Provider>
	)
}

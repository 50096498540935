import React, { useState, useEffect } from 'react'
import {
	List,
	Typography,
	Divider,
	Button,
	Paper,
	SvgIcon,
} from '@mui/material'
import BallotIcon from '@mui/icons-material/Ballot'
import { useGetManyByGroupId } from 'api/polls/getManyByGroupId'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { Poll } from 'lib/supabase'
import PollsItem from './PollsItem'

const Polls: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const navigate = useNavigate()

	const polls: Poll[] =
		useGetManyByGroupId({
			groupId: activeGroup?.id,
			options: { enabled: !!profile },
		}).data || []

	const onClick = (pollId: string) => {
		navigate(`/poll/${pollId}`)
	}

	return (
		<div style={{}}>
			{polls.length > 0 ? (
				<Paper elevation={3} style={{ margin: '10px' }}>
					<List>
						<>
							{polls &&
								polls.map((poll: Poll, index) => (
									<div key={poll.id}>
										<PollsItem
											key={poll.id}
											poll={poll}
											onClick={(pollId: string) =>
												onClick(pollId)
											}
										/>
										{index < polls.length - 1 ? (
											<Divider />
										) : (
											<></>
										)}
									</div>
								))}
						</>
					</List>
				</Paper>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						margin: '80px 40px',
					}}
				>
					<SvgIcon
						sx={{
							fontSize: '5.0rem',
							color: 'grey.700',
						}}
					>
						<BallotIcon />
					</SvgIcon>
					<Typography
						fontSize={'1.1rem'}
						fontWeight={'500'}
						textAlign={'center'}
						marginTop={'40px'}
						color={'grey.700'}
					>
						There are no polls for your group.
					</Typography>
					<Typography
						marginTop={'20px'}
						fontSize={'1.1rem'}
						fontWeight={'500'}
						textAlign={'center'}
						color={'grey.700'}
					>
						You can create one by tapping the button below.
					</Typography>
				</div>
			)}
		</div>
	)
}

export default Polls

import { getProfile } from './get'
import type { Profile } from '../../lib/supabase'
import { useQuery } from '@tanstack/react-query'
import type { QueryConfig } from '../../lib/reactQuery'

type UseGetProfilesOptions = {
	profileIds: string[]
	options?: QueryConfig<(profileIds: string[]) => Promise<Profile[]>>
}

const getProfiles = async (profileIds: string[]): Promise<Profile[]> => {
	const profiles = await Promise.all(
		profileIds.map((profileId) => getProfile({ profileId }))
	)
	return profiles
}

export const useGetProfiles = ({
	profileIds,
	options,
}: UseGetProfilesOptions) => {
	return useQuery({
		...options,
		queryKey: ['profiles', profileIds],
		queryFn: () => getProfiles(profileIds),
	})
}

import queryClient from '../../lib/reactQuery'
import { TABLE_PROMPTS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { Prompt } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreatePromptArgs = {
	prompt: Prompt
}
export const createPrompt = async ({ prompt }: CreatePromptArgs) =>
	createGeneric({ tableName: TABLE_PROMPTS, data: prompt })

type UseCreatePromptOptions = {
	options?: MutationConfig<typeof createPrompt>
}
export const useCreatePrompt = ({ options }: UseCreatePromptOptions = {}) => {
	return useMutation({
		onSuccess: (_, prompt) => {
			queryClient.setQueryData(
				['prompt', prompt.prompt.id],
				prompt.prompt
			)
		},
		...options,
		mutationFn: createPrompt,
	})
}

import React from 'react'
import { Typography, Paper } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { DateTime } from 'luxon'
import { SubscriptionInfo } from 'vercel/stripe-utils'
import { Profile } from '../../lib/supabase'
import { getStatusColor } from './util'

type Props = {
	profile: Profile
	subscription: SubscriptionInfo
}

export const PaidSubscriptionDetails: React.FC<Props> = ({
	profile,
	subscription,
}) => {
	return (
		<Paper elevation={3} style={{ padding: '5px 10px' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
					Thanks for being a Joyner subscriber
				</Typography>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Subscription Type:
				</Typography>
				<Typography>
					<CircleIcon
						style={{
							fontSize: '0.8rem',
							color: getStatusColor(subscription.status ?? ''),
						}}
					/>
					&nbsp;PAID
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Subscription Started:
				</Typography>
				<Typography>
					{subscription &&
						DateTime.fromSeconds(subscription.periodstart).toFormat(
							'MMM d, yyyy'
						)}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Subscription Ends:
				</Typography>
				<Typography>
					{subscription &&
						DateTime.fromSeconds(subscription.periodend).toFormat(
							'MMM d, yyyy'
						)}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
					width: '100%',
					textAlign: 'left',
					margin: '5px 0px 5px 0px',
				}}
			>
				<Typography width={'210px'} fontWeight={'500'}>
					Auto-Renew:
				</Typography>
				<Typography>
					{subscription && subscription.autorenew ? 'ON' : 'OFF'}
				</Typography>
			</div>
		</Paper>
	)
}

import queryClient from '../../lib/reactQuery'
import { TABLE_GROUPS_INVITES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { GroupInvite } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateInviteArgs = {
	invite: GroupInvite
}
export const createInvite = async ({ invite }: CreateInviteArgs) =>
	createGeneric({ tableName: TABLE_GROUPS_INVITES, data: invite })

type UseCreateInviteOptions = {
	options?: MutationConfig<typeof createInvite>
}
export const useCreateInvite = ({ options }: UseCreateInviteOptions = {}) => {
	return useMutation({
		onSuccess: (_, invite) => {
			queryClient.setQueryData(
				['invite', invite.invite.id],
				invite.invite
			)
		},
		...options,
		mutationFn: createInvite,
	})
}

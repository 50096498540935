import React, { useState } from 'react'
import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	AppBar,
} from '@mui/material'
import AppHeader from 'components/AppHeader'
import { useNavigate } from 'react-router'
import { useUserContext } from 'contexts/UserContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SubscribeDialog from './SubscribeDialog'
import ConfirmLeaveDialog from './ConfirmLeaveDialog'
import GroupForm, { GroupFormValues } from 'components/forms/prebuilt/GroupForm'
import { v4 as uuidV4 } from 'uuid'
import Loading from 'components/Loading'
import { useGroupsContext } from 'contexts/GroupsContext'
import { useSubscriptionContext } from 'contexts/SubscriptionContext'
import { Group, Conversation } from 'lib/supabase'
import { addUserToGroup, removeUserFromGroup } from 'api/membership'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { DateTime } from 'luxon'
import { useCreateConversation } from 'api/conversations'

export default function GroupList() {
	const { profile, activeGroup, setActiveGroup } = useUserContext()
	const { groups, createGroup, userIsAdminOfGroup } = useGroupsContext()
	const { hasSubscription, subscriptionStatus } = useSubscriptionContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const [showLeaveDialog, setShowLeaveDialog] = useState(false)
	const [showSubscribeDialog, setShowSubscribeDialog] = useState(false)
	const [leaveGroupId, setLeaveGroupId] = useState<string>()
	const [creatingNewGroup, setCreatingNewGroup] = useState(false)
	const createConversation = useCreateConversation()

	const goBack = () => {
		navigate('/')
	}

	const handleListClick = async (gid: string) => {
		await setActiveGroup(gid)
		navigate('/')
	}

	const handleNewGroup = () => {
		if (subscriptionStatus == 'active') {
			setCreatingNewGroup(true)
		} else {
			setShowSubscribeDialog(true)
		}
	}

	const handleEditButtonClick = (path: string) => {
		navigate(`/group/${path}/properties`, {
			state: { backPath: '/groups' },
		})
	}

	const handleLeaveButtonClick = (groupId: string) => {
		setShowLeaveDialog(true)
		setLeaveGroupId(groupId)
	}

	const handleLeaveGroup = async () => {
		console.log('handleLeaveGroup', profile, groups.isSuccess, leaveGroupId)
		if (profile && groups.isSuccess && leaveGroupId) {
			await removeUserFromGroup({
				userId: profile.id,
				groupId: leaveGroupId,
			})

			const newGroup = groups.data.filter(
				(group) => group.id !== leaveGroupId
			)?.[0]
			await setActiveGroup(newGroup ? newGroup.id : null)

			setShowLeaveDialog(false)
			navigate('/')
		}
	}

	const handleCreateGroup = async (values: GroupFormValues) => {
		if (!profile) throw new Error('No profile found')

		try {
			const newGroup: Group = {
				id: uuidV4(),
				name: values.name,
				activity_name: values.activityName,
				admin: profile.id,
				status: 'active',
				created_at: DateTime.now().toISO(),
				updated_at: null,
				default_timezone: DateTime.local().zoneName,
			}
			await createGroup(newGroup).then(() => {
				addUserToGroup({
					userId: profile.id,
					groupId: newGroup.id,
				})
				setActiveGroup(newGroup.id)
				const newConversation: Conversation = {
					id: uuidV4(),
					group_id: newGroup.id,
					created_at: DateTime.now().toISO(),
					created_by: profile.id,
					updated_at: null,
					last_sender_id: null,
					participants: null,
					status: 'active',
				}
				createConversation
					.mutateAsync({
						conversation: newConversation,
					})
					.then(() => {
						navigate('/')
					})
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			showSnackbar('Error creating group', 'error')
		}
	}

	const handleCancelCreateGroup = () => {
		setCreatingNewGroup(false)
	}

	const handleSubscribe = () => {
		navigate('/account')
	}

	return (
		<>
			<AppHeader
				leftComponent={
					<IconButton
						style={{
							display: creatingNewGroup ? 'none' : 'block',
							color: '#ffffff',
						}}
						onClick={goBack}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title={creatingNewGroup ? 'Create Group' : 'Groups'}
			/>

			{groups.isSuccess ? (
				creatingNewGroup ? (
					<Box margin='1rem'>
						<GroupForm
							onSubmit={handleCreateGroup}
							onCancel={handleCancelCreateGroup}
							source='groupList'
							defaultValues={{
								name: '',
								activityName: 'Activity',
								defaultTimezone:
									DateTime.local().zoneName || '',
							}}
						/>
					</Box>
				) : (
					<Box sx={{ margin: '10px 0px' }}>
						<List>
							{groups.data.map((group) => {
								const secondaryAction = userIsAdminOfGroup(
									group.id
								) ? (
									<Button
										onClick={() =>
											handleEditButtonClick(group.id)
										}
										size='small'
										variant='outlined'
									>
										Edit
									</Button>
								) : (
									<Button
										onClick={() =>
											handleLeaveButtonClick(group.id)
										}
										size='small'
										variant='outlined'
										color='warning'
									>
										Leave
									</Button>
								)

								return (
									<ListItem
										// disablePadding
										style={{
											paddingRight: '100px',
											borderBottom: '1px solid #cccccc',
										}}
										key={group.id}
										secondaryAction={secondaryAction}
									>
										<ListItemButton
											onClick={() =>
												handleListClick(group.id)
											}
											selected={
												activeGroup?.id === group.id
											}
										>
											<ListItemText>
												{group.name}
											</ListItemText>
										</ListItemButton>
									</ListItem>
								)
							})}
						</List>
					</Box>
				)
			) : (
				<Loading />
			)}

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
					visibility: creatingNewGroup ? 'hidden' : 'visible',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Button
					variant='contained'
					color='success'
					fullWidth
					onClick={handleNewGroup}
					style={{
						maxWidth: '300px',
						marginTop: '10px',
					}}
				>
					Create New Group
				</Button>
			</AppBar>

			<ConfirmLeaveDialog
				open={showLeaveDialog}
				onLeave={handleLeaveGroup}
				onClose={() => setShowLeaveDialog(false)}
			/>

			<SubscribeDialog
				open={showSubscribeDialog}
				onCancel={() => setShowSubscribeDialog(false)}
				onSubscribe={handleSubscribe}
			/>
		</>
	)
}

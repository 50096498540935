import { createClient } from '@supabase/supabase-js'
import { Database, Tables } from './database.types'

const supabaseUrl = import.meta.env.VITE_API_URL
if (!supabaseUrl) throw new Error('Supabase URL is undefined')
const supabaseAnonKey = import.meta.env.VITE_API_ANON_KEY
if (!supabaseAnonKey) throw new Error('Supabase Anon Key is undefined')
const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey)

export default supabase

export const TABLE_ACTIVITIES = 'activities'
export const TABLE_ACTIVITIES_ATTENDANCE = 'activities_attendance'
export const TABLE_PROFILES = 'profiles'
export const TABLE_GROUPS = 'groups'
export const TABLE_GROUPS_VENUES = 'groups_venues'
export const TABLE_CHAT_MESSAGES = 'chat_messages'
export const TABLE_CHAT_CONVERSATIONS = 'chat_conversations'
export const TABLE_CHAT_REACTIONS = 'chat_reactions'
export const TABLE_GROUPS_INVITES = 'groups_invites'
export const TABLE_GROUPS_MEMBERSHIP = 'groups_membership'
export const TABLE_ACTIVITIES_GUESTS = 'activities_guests'
export const TABLE_POLLS = 'polls'
export const TABLE_POLLS_CHOICES = 'polls_choices'
export const TABLE_PROMPTS = 'prompts'
export const TABLE_EVENTS_TEXTBLOCKS = 'events_textblocks'

export type TableNames = keyof Database['public']['Tables']
export type Activity = Tables<typeof TABLE_ACTIVITIES>
export type ActivityAttendance = Tables<typeof TABLE_ACTIVITIES_ATTENDANCE>
export type ActivityGuest = Tables<typeof TABLE_ACTIVITIES_GUESTS>
export type Profile = Tables<typeof TABLE_PROFILES>
export type Group = Tables<typeof TABLE_GROUPS>
export type GroupVenue = Tables<typeof TABLE_GROUPS_VENUES>
export type GroupInvite = Tables<typeof TABLE_GROUPS_INVITES>
export type GroupMembership = Tables<typeof TABLE_GROUPS_MEMBERSHIP>
export type Message = Tables<typeof TABLE_CHAT_MESSAGES>
export type Poll = Tables<typeof TABLE_POLLS>
export type PollChoice = Tables<typeof TABLE_POLLS_CHOICES>
export type Conversation = Tables<typeof TABLE_CHAT_CONVERSATIONS>
export type Reaction = Tables<typeof TABLE_CHAT_REACTIONS>
export type Prompt = Tables<typeof TABLE_PROMPTS>
export type EventTextBlock = Tables<typeof TABLE_EVENTS_TEXTBLOCKS>

export interface JoinedActivity extends Activity {
	activities_attendance: ActivityAttendance[]
	activities_guests: ActivityGuest[]
}

import { getGeneric } from '../generic'
import { TABLE_GROUPS_VENUES } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'

import type { GroupVenue } from '../../lib/supabase'
import type { QueryConfig } from '../../lib/reactQuery'
import queryClient from '../../lib/reactQuery'

type GetVenueArgs = {
	venueId: string
}
const getVenueFunction = async ({ venueId }: GetVenueArgs) =>
	getGeneric<GroupVenue>({ tableName: TABLE_GROUPS_VENUES, id: venueId })

export const getVenue = async ({ venueId }: GetVenueArgs) =>
	queryClient.fetchQuery({
		queryKey: ['venue', venueId],
		queryFn: () => getVenueFunction({ venueId }),
	})

type UseGetVenueOptions = {
	venueId: string
	options?: QueryConfig<typeof getVenueFunction>
}
export const useGetVenue = ({ venueId, options }: UseGetVenueOptions) => {
	return useQuery({
		...options,
		queryKey: ['venue', venueId],
		queryFn: () => getVenueFunction({ venueId }),
	})
}

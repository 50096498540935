import queryClient from '../../lib/reactQuery'
import { TABLE_EVENTS_TEXTBLOCKS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { EventTextBlock } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreateEventTextBlockArgs = {
	textblock: EventTextBlock
}
export const createEventTextBlock = async ({
	textblock,
}: CreateEventTextBlockArgs) =>
	createGeneric({ tableName: TABLE_EVENTS_TEXTBLOCKS, data: textblock })

type UseCreateEventTextBlockOptions = {
	options?: MutationConfig<typeof createEventTextBlock>
}
export const useCreateEventTextBlock = ({
	options,
}: UseCreateEventTextBlockOptions = {}) => {
	return useMutation({
		onSuccess: (_, textblock) => {
			queryClient.setQueryData(
				['textblock', textblock.textblock.id],
				textblock.textblock
			)
		},
		...options,
		mutationFn: createEventTextBlock,
	})
}

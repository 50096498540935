import React from 'react'
import { Stack, Typography, IconButton, Button } from '@mui/material'
import { Profile, Message } from 'lib/supabase'

type MessagePopoverProps = {
	message: Message
	profile: Profile | null
	userIsAuthor: boolean
	userIsAdmin: boolean
	onCopy: (contentToCopy: string | '') => void
	onDelete: (messageId: string) => void
	onCancel: () => void
	onReaction: (reply: string) => void
}

const MessagePopover: React.FC<MessagePopoverProps> = ({
	message,
	profile,
	userIsAuthor,
	userIsAdmin,
	onCopy,
	onDelete,
	onCancel,
	onReaction,
}) => {
	const thumbsUp = String.fromCodePoint(0x1f44d)
	const thankYou = String.fromCodePoint(0x1f64f)
	const heart = String.fromCodePoint(0x2764, 0xfe0f)
	const laugh = String.fromCodePoint(0x1f923)
	const sad = String.fromCodePoint(0x1f622)
	// const smile = String.fromCodePoint(0x1f604)
	// const angry = String.fromCodePoint(0x1f620)

	const handleReaction = (reply: string) => {
		onReaction(reply)
	}

	return (
		<div style={{ padding: '20px', width: '90vw' }}>
			<Stack direction={'column'} gap={0}>
				<Stack direction={'row'} gap={1} justifyContent={'flex-start'}>
					<IconButton
						disableRipple
						onClick={() => handleReaction(thumbsUp)}
					>
						{thumbsUp}
					</IconButton>
					<IconButton
						disableRipple
						onClick={() => handleReaction(heart)}
					>
						<Typography fontSize={'1.4rem'}>{heart}</Typography>
					</IconButton>
					<IconButton
						disableRipple
						onClick={() => handleReaction(thankYou)}
					>
						<Typography fontSize={'1.4rem'}>{thankYou}</Typography>
					</IconButton>
					<IconButton
						disableRipple
						onClick={() => handleReaction(laugh)}
					>
						<Typography fontSize={'1.4rem'}>{laugh}</Typography>
					</IconButton>
					<IconButton
						disableRipple
						onClick={() => handleReaction(sad)}
					>
						<Typography fontSize={'1.4rem'}>{sad}</Typography>
					</IconButton>
				</Stack>
				<Stack
					direction={'row'}
					gap={1}
					justifyContent={'sapce-around'}
					paddingTop={'20px'}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => onCopy(message.body)}
					>
						Copy Message
					</Button>
					<Button
						variant='outlined'
						color='error'
						disabled={!(userIsAuthor || userIsAdmin)}
						onClick={() => onDelete(message.id)}
					>
						Delete Message
					</Button>
				</Stack>
			</Stack>
		</div>
	)
}
export default MessagePopover

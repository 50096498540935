import { useQuery } from '@tanstack/react-query'
import queryClient from 'lib/reactQuery'
import supabase, { type Profile, TABLE_GROUPS_MEMBERSHIP } from 'lib/supabase'

import type { QueryConfig } from 'lib/reactQuery'

export const getManyQueryKey = (groupId?: string) => [
	'group',
	groupId,
	'profiles',
]

type GetManyByGroupIdArgs = {
	groupId: string
}
const getManyByGroupIdFunction = async ({ groupId }: GetManyByGroupIdArgs) => {
	const { data, error } = await supabase
		.from(TABLE_GROUPS_MEMBERSHIP)
		.select(
			`
			profiles (*)
		`
		)
		.eq('group_id', groupId)

	if (error) throw error

	const profiles = data
		.map((membership) => membership.profiles)
		.filter((profile) => profile != null) as Profile[]

	return profiles
}

export const getManyByGroupId = async ({ groupId }: GetManyByGroupIdArgs) =>
	queryClient.fetchQuery({
		queryKey: getManyQueryKey(groupId),
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})

type UseGetManyByGroupIdOptions = {
	groupId: string
	options?: QueryConfig<typeof getManyByGroupIdFunction>
}
export const useGetManyByGroupId = ({
	groupId,
	options,
}: UseGetManyByGroupIdOptions) => {
	return useQuery({
		...options,
		queryKey: getManyQueryKey(groupId),
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})
}

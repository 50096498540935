import supabase, {
	TABLE_PROFILES,
	TABLE_ACTIVITIES,
	TABLE_ACTIVITIES_ATTENDANCE,
	TABLE_GROUPS,
	TABLE_GROUPS_VENUES,
	TABLE_CHAT_MESSAGES,
	TABLE_CHAT_CONVERSATIONS,
	TABLE_CHAT_REACTIONS,
	TABLE_GROUPS_INVITES,
	TABLE_ACTIVITIES_GUESTS,
	TABLE_POLLS,
	TABLE_POLLS_CHOICES,
	TABLE_PROMPTS,
	TABLE_EVENTS_TEXTBLOCKS,
} from 'lib/supabase'
import type {
	Group,
	Profile,
	Activity,
	ActivityAttendance,
	GroupVenue,
	Message,
	GroupInvite,
	ActivityGuest,
	Poll,
	PollChoice,
	Conversation,
	Reaction,
	Prompt,
	EventTextBlock,
} from 'lib/supabase'

type CreateGenericArgs =
	| {
			tableName: typeof TABLE_PROFILES
			data: Profile
	  }
	| {
			tableName: typeof TABLE_ACTIVITIES
			data: Activity
	  }
	| {
			tableName: typeof TABLE_ACTIVITIES_ATTENDANCE
			data: ActivityAttendance
	  }
	| {
			tableName: typeof TABLE_GROUPS
			data: Group
	  }
	| {
			tableName: typeof TABLE_GROUPS_VENUES
			data: GroupVenue
	  }
	| {
			tableName: typeof TABLE_CHAT_MESSAGES
			data: Message
	  }
	| {
			tableName: typeof TABLE_GROUPS_INVITES
			data: GroupInvite
	  }
	| {
			tableName: typeof TABLE_ACTIVITIES_GUESTS
			data: ActivityGuest
	  }
	| {
			tableName: typeof TABLE_POLLS
			data: Poll
	  }
	| {
			tableName: typeof TABLE_POLLS_CHOICES
			data: PollChoice
	  }
	| {
			tableName: typeof TABLE_CHAT_CONVERSATIONS
			data: Conversation
	  }
	| {
			tableName: typeof TABLE_CHAT_REACTIONS
			data: Reaction
	  }
	| {
			tableName: typeof TABLE_PROMPTS
			data: Prompt
	  }
	| {
			tableName: typeof TABLE_EVENTS_TEXTBLOCKS
			data: EventTextBlock
	  }

export const createGeneric = async ({ tableName, data }: CreateGenericArgs) => {
	if (
		tableName === TABLE_ACTIVITIES_ATTENDANCE &&
		data?.activity_id == null
	) {
		throw new Error('Function data is not defined')
	} else if (tableName != TABLE_ACTIVITIES_ATTENDANCE && data?.id == null) {
		throw new Error('Function data is not defined')
	}

	const { data: createdData, error } = await supabase
		.from(tableName)
		.insert(data)
		.select()
		.single<typeof data>()

	if (error) throw error

	return createdData
}
